import Api from "@/api/api";
import authHeader from "@/services/business/auth/auth-header";
class FlightService {
    get_flight_offers(params) {
        return Api.get("api/business/flights/result", { headers: authHeader(), params })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    get_flights_pricing(body) {
        return Api.post("api/business/flights/pricing", body, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    get_company_policy(body) {
        return Api.post("api/business/company/get_company_policy", body, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    send_booking_order(body) {
        return Api.post("api/business/flights/order", body, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
}
export default new FlightService();