<template>
    <form @submit.prevent="VerfieyCode">
        <div>
            <div class="mb-4 text-center">
                <h4 class="color">{{ $t('Enter the verification code') }}</h4>
                <p class="mb-2 mt-3">{{ $t('We have sent the code to your registered number') }}</p>
                <p class="text-muted" v-if="info" dir="auto">
                    {{ info.phone.slice(0, 4) }}
                    <span v-for="item in info.phone.length - 7" :key="item">*</span>
                    {{ info.phone.slice(info.phone.length - 3) }}
                </p>
            </div>
            <div id="timer">
                <p id="currenttimer" class="text-center"></p>
            </div>
            <div class="mb-3">
                <lv-input id="code" type="number" v-model="code"
                    :placeholder="$t('Validation code')" placeholder-color="#84818A" />
            </div>
            <div class=" mb-3 d-flex justify-content-center align-items-center">
                <button type="submit" class="btn text-white px-5 mt-5"
                    style="background-color: #D1AF77; border-color:#D1AF77">
                    <p class=" mb-0 ">{{ $t('Verify mobile number') }}</p>
                </button>
            </div>
            <div class="d-flex justify-content-center mt-3">
                <span id="resend" class="btn visually-hidden" @click="sendagain">
                    {{ $t('Resend the code') }}
                </span>
            </div>
        </div>
    </form>
</template>

<script>
import $ from 'jquery';
import { toast } from "vue3-toastify";
import LvInput from 'lightvue/input';
import ripple from 'lightvue/ripple';
import LvLoader from 'lightvue/loaders';
import authenticationServices from '@/services/business/auth/auth-service';
export default {
    name: "VerfyCodeComponent",
    components: {
        LvInput,
        LvLoader
    },
    directives: {
        ripple: ripple,
    },
    props: ["info", "password"],
    data() {
        return {
            code: '',
            lang: localStorage.getItem('lang'),
        }
    },
    methods: {
        async sendagain() {
            $(".pre-loader").show();
            let data = {
                email: this.info.email,
                password: this.password,
                lang: this.lang
            }
            await authenticationServices.Login(data).then(res => {
                $(".pre-loader").hide();
                if (res.status == 0) {
                    toast.error(res.message)
                } else {
                    this.$emit('startTimer')
                    toast.success(res.data.message)
                    document.getElementById('resend').classList.add('visually-hidden')
                }
            })
        },
        async VerfieyCode() {
            $(".pre-loader").show();
            let data = {
                email: this.info.email,
                code: this.code,
                lang: this.lang
            }
            await authenticationServices.CheckCode(data).then(async res => {
                if (res.status == 0) {
                    toast.error(res.message)
                } else {
                    toast.success(res.data.message);
                    localStorage.setItem('business_token', res.data.access_token)
                    await authenticationServices.get_user_data().then(res => {
                        if (res.data.status == 1) {
                            $(".pre-loader").hide();
                            localStorage.setItem("user-info", JSON.stringify(res.data.data));
                            localStorage.setItem("company-profile", JSON.stringify(res.data.data.company));
                            location.reload()
                        }
                    })
                }
            });
        }
    },
    watch: {
        code() {
            if (this.code.length == 6) {
                this.VerfieyCode()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.lv-button {
    background-color: #D1AF77 !important;
}

.throw {
    color: black !important;
    text-decoration: line-through !important;
}


</style>