function load(component) {
    return () => import(`@/views/business/settings/${component}.vue`);
}
const auth = (to, from, next) => {
  if (localStorage.getItem("business_token")) {
    return next();
  } else {
    return next("/login");
  }
};
const SettingRoutes = {
    path: "/setting",
    alwaysShow: true,
    beforeEnter: auth,
    children: [
        {
            path: "travel-purposes",
            name: "travel-purposes",
            component: load("travelPurposesView"),
            meta: { title: "travelPurposes", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "travel-purposes/edit/:id",
            name: "edit-travel-purposes",
            component: load("editTravelPropuseView"),
            meta: { title: "edit Travel Propuse", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "travel-purposes/add",
            name: "add-travel-purposes",
            component: load("AddtravelPurposesView"),
            meta: { title: "AddtravelPurposes", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "policies",
            name: "policies",
            component: load("policiesView"),
            meta: { title: "Reservationpolicies", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "policies/add",
            name: "add-policy",
            component: load("AddpoliciesView"),
            meta: { title: "Addpolicies", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "policies/edit/:id",
            name: "edit-policy",
            component: load("EditpoliciesView"),
            meta: { title: "Editpolicies", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "policies/:id/apply",
            name: "applying-policy",
            component: load("ApplyPolicyView"),
            meta: { title: "Apply Policy", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "policies/:id/apply/add",
            name: "add-apply-policy",
            component: load("AddApplyPolicyView"),
            meta: { title: "Add Apply Policy", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "policies/:id/apply/edit/:id2",
            name: "edit-apply-policy",
            component: load("editApplyPolicyView"),
            meta: { title: "edit Apply Policy", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "grades",
            name: "employee-grades",
            component: load("gradesView"),
            meta: { title: "Job grades", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "payment-methods",
            name: "payment-methods",
            component: load("paymentmethodsView"),
            meta: { title: "payment methods", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "paymentmethods/AddCreditCard",
            name: "credit-cards",
            component: load("AddCreditCardView"),
            meta: { title: "Add Credit Card", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "paymentmethods/AddElectronicWallet",
            name: "electronic-wallet",
            component: load("AddElectronicWalletView"),
            meta: { title: "Add Electronic Wallet", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "users",
            name: "users",
            component: load("UsersView"),
            meta: { title: "Users", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "users/add",
            name: "add-new-user",
            component: load("AddNewAdministratorView"),
            meta: { title: "Add Administrator", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "users/edit/:id",
            name: "edit-user",
            component: load("EditadministratorView"),
            meta: { title: "Edit Administrator", noCache: true },
            beforeEnter: auth,
        },
        
    ]
};

export default SettingRoutes;