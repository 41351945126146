export default function authHeader() {
    let business_token = localStorage.getItem("business_token");
    const lang = localStorage.getItem("lang") || "ar";
    if (business_token) {
        return {
            Authorization: "Bearer " + business_token,
            Accept: "*/*",
            "Content-Type": "application/json",
        };
    } else {
        return {
            lang: lang,
        };
    }
}