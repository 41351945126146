<template>
    <form @submit.prevent="login">
        <div>
            <h4 class="text-center color mb-5">{{ $t('Log in') }}</h4>
            <div class="mb-4">
                <label for="" class="mb-2" style="color: #84818A;">{{ $t('email') }}</label>
                <input type="email" required class="form-control" v-model="body.email" :placeholder="$t('email')" autocomplete="off">
            </div>
            <div class="mb-5 mt-4">
                <lv-input id="CompanyName" type="password" v-model="body.password" :label="$t('password')"
                    :placeholder="$t('password')" icon-right="light-icon-eye" autocomplete="new-password" placeholder-color="#84818A" />
            </div>
            <div class=" mb-4 d-flex justify-content-between align-items-center" style="margin-top: 2rem !important;">
                <div>
                    <router-link to="/forget-password" style="color: #47464A;" class="text-decoration-none">
                        <span>{{ $t('Forget password') }}</span>
                    </router-link>
                </div>
                <button type="submit" class="btn px-5 text-white"
                    style="background-color: #D1AF77;border-color: #D1AF77;">
                    <p class=" mb-0 ">{{ $t('LogIn') }}</p>
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import $ from 'jquery';
import { toast } from "vue3-toastify";
import authenticationService from "@/services/business/auth/auth-service"
import LvInput from 'lightvue/input';
import ripple from 'lightvue/ripple';
import LvLoader from 'lightvue/loaders';
export default {
    name: "LoginComponent",
    components: {
        LvInput,
        LvLoader
    },
    directives: {
        ripple: ripple,
    },
    props: ["email"],
    watch: {
        emailincomponent() {
            this.$emit('EditEmail', this.body.email)
        }
    },
    data() {
        return {
            body: {
                email: '',
                password: '',
                lang: localStorage.getItem('lang')
            }
        }
    },
    methods: {
        showPassword(event) {
            if (event.target.parentNode.parentNode.parentNode.children[0].getAttribute("type") == null || event.target.parentNode.parentNode.parentNode.children[0].getAttribute("type") == 'password') {
                event.target.parentNode.parentNode.parentNode.children[0].setAttribute('type', 'text')
            } else {
                event.target.parentNode.parentNode.parentNode.children[0].setAttribute('type', 'password')
            }
        },
        async login() {
            $(".pre-loader").show();
            await authenticationService.Login(this.body).then(res => {
                $(".pre-loader").hide();
                if (res.status == 0) {
                    $(".pre-loader").hide();
                    if (res.login_attempts != null) {
                        if (res.login_attempts == 0) {
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('the account is closed'),
                                confirmButtonColor: '#196dfb',
                                text: res.message,
                            });
                        } else {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                confirmButtonColor: '#196dfb',
                                text: res.message,
                            });
                        }
                    } else {
                        toast.error(res.message)
                    }
                } else {
                    this.$emit('setRes', res.data)
                    this.$emit('setPass', this.body.password)
                    this.$emit('startTimer')
                    toast.success(res.data.message)
                    setTimeout(() => {
                        $('#loginStepTow').show();
                        $('#loginStepOne').hide();
                    }, 200);
                }
            });
        },
    },
    mounted() {
        document.getElementsByClassName('light-icon-eye')[0].addEventListener("click", this.showPassword)
    },
}
</script>

<style lang="scss" scoped>
.lv-button {
    background-color: #D1AF77 !important;
}

.throw {
    color: black !important;
    text-decoration: line-through !important;
}
.form-control{
    height: 45px;
    &::placeholder{
        color: #84818A;
    }
}
.lv-input__group .lv-input__field{
    height: 45px;
}
</style>