<template>
    <div class="box" dir="auto">
        <div class="content ">
            <div class=" d-flex justify-content-start text-ingo px-4 py-1 mb-5">
                <p class="mb-0">{{ $t('Do not have an account?') }}</p>
                <router-link to="/register" class="text-decoration-none text-black">
                    <p class="mb-0 mx-2">{{ $t('Create a new account') }}</p>
                </router-link>
            </div>
            <div class="formBox">
                <div id="loginStepOne">
                    <login-component @setPass="setPass" @startTimer="startTimer" @setRes="setRes" :email="email"
                        @EditEmail="EditEmail"></login-component>
                </div>
                <div class="d-none2" id="loginStepTow">
                    <VerfyCodeComponent :password="password" :info="info" @startTimer="startTimer" />
                </div>
            </div>
        </div>
        <div class="image">
            <img class="logoregister" src="@/assets/images/register/login.png" alt="RegisterLogo" />
            <!-- JSKY Logo -->
            <img src="@/assets/images/logo/logo1.png" class="logoimage" alt="logo1" />
            <!-- Text -->
            <div class="text-box">
                <h2 class="text-white mb-3">{{ $t("Business login") }}</h2>
                <h5 class="text-white">
                    {{ $t("Log in as a business account") }}
                </h5>
            </div>
            <!-- Proplem -->
            <!-- <div class="problem">
                <router-link to="#" class="text-decoration-none text-white">
                    {{ $t("there is a problem ? Connect with us") }}
                </router-link>
            </div> -->
        </div>
    </div>
</template>
<script>
import LoginComponent from '@/components/business/login/LoginComponent.vue';
import VerfyCodeComponent from '@/components/business/login/VerfyCodeComponent.vue';
import { useRoute } from 'vue-router';
export default {
    name: "LoginView",
    components: { LoginComponent, VerfyCodeComponent },
    data() {
        return {
            email: useRoute().query.email ? useRoute().query.email : "",
            info: null,
            password: null
        }
    },
    methods: {
        setPass(val) {
            this.password = val
        },
        EditEmail(val) {
            this.email = val
        },
        setRes(res) {
            this.info = res
        },
        countdown(element, minutes, seconds) {
            // set time for the particular countdown
            var time = minutes * 60 + seconds;
            var interval = setInterval(function () {
                var el = document.getElementById(element);
                // if the time is 0 then end the counter
                if (time <= 0) {
                    clearInterval(interval);
                    el.innerHTML = "00:00";
                    document.getElementById('resend').classList.remove('visually-hidden')
                    return;
                }
                var minutes = Math.floor(time / 60);
                if (minutes < 10) minutes = "0" + minutes;
                var seconds = time % 60;
                if (seconds < 10) seconds = "0" + seconds;
                var timeline = minutes + ':' + seconds;
                el.innerHTML = timeline;
                time--;
            }, 1000);
        },
        // recounter(){
        //     this.countdown('currenttimer', 3, 0);
        // },
        startTimer() {
            this.countdown('currenttimer', 3, 0);
        }

    }
};
</script>

<style lang="scss" scoped>
.box {
    width: 100%;
    height: 100vh;
    // background-color: red;
    display: flex;
    justify-content: center;

    .image {
        width: 35%;
        height: 100%;
        position: relative;

        .logoregister {
            width: 100% !important;
            height: 100% !important;
        }

        .logoimage {
            width: 270px;
            position: absolute;
            top: 10%;
            left: 50%;
            transform: translateX(-50%);
        }

        .text-box {
            width: 100%;
            position: absolute;
            top: 24%;
            text-align: center;
            padding: 1rem;
            left: 0%;
        }

        .problem {
            width: 100%;
            position: absolute;
            bottom: 5%;
            text-align: left;
            padding: 1rem;
            left: 0%;
        }
    }

    .content {
        width: 65%;
        height: 100%;
        // background-color: green;
        padding: 1rem;

        .formBox {
            // border: 1px solid red;
            border-radius: 8px;
            padding: 2rem 2rem;
            width: 55%;
            //   box-shadow: 0px 12px 23px 0px #d1af7797;

            box-shadow: rgb(0 0 0 / 15%) 0px 1px 5px;
            margin: 8rem auto;
        }
    }
}

@media screen and (max-width: 992px) {

    .text-ingo {
        display: block !important;
    }

    .box {
        flex-direction: column !important;
        height: auto !important;

        .image {
            width: 100% !important;
            height: auto !important;
        }

        .content {
            width: 100%;
            height: auto !important;
        }

        .formBox {
            margin: 1rem auto !important;
            width: 98% !important;
        }
    }
}

.animatetohide {
    animation: hideen 2s;
    -webkit-animation: hideen 2s;
    -moz-animation: hideen 2s;
    -o-animation: hideen 2s;
    -ms-animation: hideen 2s;
    animation-duration: 2s;
}

@keyframes hideen{
    0%{
        transform: rotateY(0deg);
    }
    100%{
        transform: rotateY(-90deg);
    }
}

.rotatehidden {
    transform: rotateY(-90deg);
}

.animatetoshow {
    animation: show 2s;
    -webkit-animation: show 2s;
    -moz-animation: show 2s;
    -o-animation: show 2s;
    -ms-animation: show 2s;
    animation-duration: 2s;
}
@keyframes show{
    0%{
        transform: rotateY(-90deg);
    }
    100%{
        transform: rotateY(0deg);
        
    }
}
</style>
