<template>
    <NavbarComponent />
    <SidebarComponentVue />
    <div dir="auto">
        <div class="body_content" :class="$i18n.locale == 'en' ? '' : 'body_content_ar'">
            <!-- Nav Pills -->
            <div class="d-flex  justify-content-center overflow-hidden">
                <ul class="nav nav-pills  px-5 py-2 d-flex justify-content-center justify-content-sm-start nav-pills-mobile mb-3 mt-3"
                    id="pills-tab1" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button data-index="onlyGo" class="nav-link active py-2" id="v-pills-onlyGo-tab"
                            data-bs-toggle="pill" data-bs-target="#v-pills-onlyGo" type="button" role="tab"
                            aria-controls="v-pills-onlyGo" aria-selected="true">
                            <span class="fs-sm-4">{{ $t("Only Go") }}</span>
                        </button>
                    </li>
                    <li class="nav-item mx-5" role="presentation">
                        <button data-index="GoAndBack" class="nav-link  py-2 " id="v-pills-GoAndBack-tab"
                            data-bs-toggle="pill" data-bs-target="#v-pills-GoAndBack" type="button" role="tab"
                            aria-controls="v-pills-GoAndBack" aria-selected="true">
                            <span class="fs-sm-4">{{ $t("going and coming back") }}</span>
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button data-index="diffrentdestination" class="nav-link py-2 "
                            id="v-pills-diffrentdestination-tab" data-bs-toggle="pill"
                            data-bs-target="#v-pills-diffrentdestination" type="button" role="tab"
                            aria-controls="v-pills-diffrentdestination" aria-selected="true">
                            <span class="fs-sm-4">{{ $t("Multiple destinations") }}</span>
                        </button>
                    </li>
                </ul>
                <ul class="nav nav-pills w-75  visually-hidden px-5 py-2  mb-3 mt-3" id="pills-tab2" role="tablist">
                    <li class="nav-item " role="presentation">
                        <button data-index="result" class="nav-link py-2 " id="v-pills-result-tab" data-bs-toggle="pill"
                            data-bs-target="#v-pills-result" type="button" role="tab" aria-controls="v-pills-result"
                            aria-selected="true">
                            <span class="fs-sm-4">{{ $t("Research results") }}</span>
                        </button>
                    </li>
                </ul>

            </div>
            <!-- Nav Pills -->
            <!--  -->
            <div class="tab-content  mt-3 px-3 pb-3" id="pills-tab1Content">
                <div class="tab-pane fade   active show" id="v-pills-onlyGo" role="tabpanel"
                    aria-labelledby="v-pills-onlyGo-tab">
                    <div class="d-flex justify-content-center mainbox">
                        <div class="leftmainbox">
                            <img class="image" src="@/assets/images/addreservation/add.png" alt="">
                        </div>
                        <div class="rightmainbox text-white" style="">
                            <one-way-component @reset_search_employee="reset_search_employee"
                                @search_employee="search_employee" @get_all_employees="get_all_employees"
                                :loading="loading" :params="params" :rows="rows" :total_rows="total_rows"
                                :travel_proupose="travel_proupose" @get_travel_prouposes="get_travel_prouposes" :travel_prouposes_params="travel_prouposes_params"
                                :loader_travel_prouposes="loader_travel_prouposes"></one-way-component>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade    show" id="v-pills-GoAndBack" role="tabpanel"
                    aria-labelledby="v-pills-GoAndBack-tab">
                    <div class="d-flex justify-content-center mainbox">
                        <div class="leftmainbox">
                            <img class="image" src="@/assets/images/addreservation/add.png" alt="">
                        </div>
                        <div class="rightmainbox text-white" style="">
                            <RoundtripComponent @reset_search_employee="reset_search_employee"
                                @search_employee="search_employee" @get_all_employees="get_all_employees"
                                :loading="loading" :params="params" :rows="rows" :total_rows="total_rows"
                                :travel_proupose="travel_proupose" @get_travel_prouposes="get_travel_prouposes" :travel_prouposes_params="travel_prouposes_params"
                                :loader_travel_prouposes="loader_travel_prouposes" />
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade    show" id="v-pills-diffrentdestination" role="tabpanel"
                    aria-labelledby="v-pills-diffrentdestination-tab">
                    <div class="d-flex justify-content-center mainbox">
                        <div class="leftmainbox">
                            <img class="image" src="@/assets/images/addreservation/add.png" alt="">
                        </div>
                        <div class="rightmainbox text-white" style="">
                            <MultiCityComponent @reset_search_employee="reset_search_employee"
                                @search_employee="search_employee" @get_all_employees="get_all_employees"
                                @get_travel_prouposes="get_travel_prouposes" :loading="loading" :params="params"
                                :rows="rows" :total_rows="total_rows" :travel_proupose="travel_proupose"
                                :travel_prouposes_params="travel_prouposes_params"
                                :loader_travel_prouposes="loader_travel_prouposes" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import SidebarComponentVue from '@/components/business/layout/SidebarComponent.vue';
import NavbarComponent from '@/components/business/layout/NavbarComponent.vue';
import OneWayComponent from '@/components/business/new-booking/OneWayComponent.vue';
import RoundtripComponent from '@/components/business/new-booking/RoundtripComponent.vue';
import employeeService from '@/services/business/company/employee-service';
import MultiCityComponent from '@/components/business/new-booking/MultiCityComponent.vue';
import travelPurposesService from "@/services/business/settings/travel-purposes-service";

export default {
    name: "addReservationView",
    components: {
        SidebarComponentVue,
        NavbarComponent,
        OneWayComponent,
        RoundtripComponent,
        MultiCityComponent
    },
    data() {
        return {
            rows: [],
            total_rows: 0,
            loading: true,
            travel_prouposes_params: {
                search: '',
                page: 1,
                pagesize: 5,
            },
            params: {
                page: 1,
                pagesize: 9,
            },
            travel_proupose: [],
            loader_travel_prouposes: false
        }
    },
    methods: {
        async get_all_employees() {
            this.loading = true;
            await employeeService.Get_All_Employee(this.params).then(res => {
                if (res.data.employees.data.length > 0) {
                    this.rows = res?.data?.employees?.data;
                    this.total_rows = res?.data?.employees?.total;
                }
            })
            this.loading = false;
        },
        async search_employee(paylod) {
            this.loading = true
            await employeeService.Search_In_All_Employee(JSON.stringify(paylod)).then(res => {
                if (res.data.employees.data.length > 0) {
                    this.rows = res?.data?.employees?.data;
                    this.total_rows = res?.data?.employees?.total;
                }
            })
            this.loading = false
        },
        async reset_search_employee() {
            await this.get_all_employees();
        },
        async get_travel_prouposes() {
            this.loader_travel_prouposes = true;
            await travelPurposesService.Get_All_TravelProupose(this.travel_prouposes_params).then(res => {
                this.travel_proupose = [];
                var travel_proupose = res.data.data.data;
                travel_proupose.forEach(element => {
                    this.travel_proupose.push({ title: element.title_for_admin, value: element.id });
                });
            });
            this.loader_travel_prouposes = false;
        }
    },
    mounted() {
        this.title = this.$t('select2');
        this.get_all_employees();
        this.get_travel_prouposes();
    }
};
</script>
<style lang="scss" scoped>
.image {
    width: 100%;
    height: 100%;
}

.nav-pills {
    background-color: #2F363D;
    border-radius: 5px;
    
    .nav-link {
        color: white;
        padding-left: 0;
        padding-right: 0;
        
        &:hover {
            color: #D1AF77;
            background-color: transparent;
            position: relative;
            &::after {
                content: ("");
                height: 1px !important;
                width: 80%;
                position: absolute;
                bottom: 0;
                background-color: #D1AF77;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        &.active {
            color: #D1AF77;
            background-color: transparent;
            position: relative;
            &::after {
                content: ("");
                height: 1px !important;
                width: 80%;
                position: absolute;
                bottom: 0;
                background-color: #D1AF77;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}

.mainbox {
    background: #272E35;
    border-radius: 6px;
    
    .leftmainbox {
        width: 40%;
    }

    .rightmainbox {
        width: 60%;
    }
    
}



@media screen and (max-width: 992px) {

    .mainbox {
        flex-direction: column;
    }
    .leftmainbox {
        width: 100% !important;
        height: 400px !important;
    }

    .rightmainbox {
        width: 100% !important;
    }
}


// Last Step
.description {
    .price_description {
        background-color: white;
        border: 1px solid white;
        border-radius: 12px;
        padding: 5px 1rem;

    }
    .title_price {
        color: #888888;
    }

    .desc {
        height: 300px;
        overflow-y: auto;
        
    }

    .passenger {
        background-color: white;
            border: 1px solid white;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            padding: 15px 1rem;
    }

    .color {
        color: #D1AF77;
    }

    .color-1::after {
        content: ("");
        height: 2px !important;
        width: 90%;
        position: absolute;
        bottom: -3px;
        background-color: #D1AF77;
        left: 50%;
        transform: translateX(-50%);
    }

    .te {
        font-family: Cairo;
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0.5px;

    }
    .te1 {
        font-family: Cairo;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;


    }
}
</style>