<template>
    <div>
        <PageNotFound />
    </div>
</template>
<script>
import PageNotFound from '@/components/pages/PageNotFound.vue';
export default {
    name: "NotFoundVue",
    components: { PageNotFound },
};
</script>