import { createRouter, createWebHistory } from "vue-router";

// For Moudels
import BookingRoutes from "@/router/modules/bookings"
import CompanyRoutes from "@/router/modules/company"
import InvoiceRoutes from "@/router/modules/invoice"
import SettingRoutes from "@/router/modules/settings"
import GroupAuth from "@/router/modules/group"
// For Moudels

import DashboardView from "@/views/business/dashboard/DashboardView";
import LoginView from "@/views/business/login/LoginView";
import ResetPasswordView from "@/views/guest/ResetPasswordView";
import ForgetPasswordView from "@/views/business/forget-password/ForgetPasswordView";
import inviteUserView from "@/views/guest/inviteUserView";
import RegisterView from "@/views/business/register/RegisterView";
import NewBookingView from "@/views/business/new-booking/NewBookingView";
import FlightResultView from "@/views/business/new-booking/FlightResultView";
import NotFound from "@/views/pages/NotFoundVue";

const guest = (to, from, next) => {
    if (!localStorage.getItem("business_token")) {
        return next();
    } else {
        return next("/dashboard");
    }
};

const auth = (to, from, next) => {
    if (localStorage.getItem("business_token")) {
        return next();
    } else {
        return next("/login");
    }
};

const routes = [
    BookingRoutes,
    CompanyRoutes,
    InvoiceRoutes,
    SettingRoutes,
    GroupAuth,
    {
        path: "/login",
        name: "login",
        component: LoginView,
        beforeEnter: guest,
    },
    {
        path: "/",
        component: DashboardView,
        beforeEnter: auth,
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: DashboardView,
        beforeEnter: auth,
    },
    {
        path: "/register",
        name: "register",
        component: RegisterView,
        beforeEnter: guest,
    },
    {
        path: "/forget-password",
        name: "forget_password",
        component: ForgetPasswordView,
        beforeEnter: guest,
    },
    {
        path: "/forgot-password/reset/:id",
        name: "resetpassword",
        component: ResetPasswordView,
        beforeEnter: guest,
    },
    {
        path: "/inviteUser/:id",
        name: "inviteUser",
        component: inviteUserView,
        beforeEnter: guest,
    },
    {
        path: "/booking/new",
        name: "new-booking",
        component: NewBookingView,
        beforeEnter: auth,
    },
    {
        path: "/flight-result/result",
        name: "flight-result",
        component: FlightResultView,
        beforeEnter: auth,
    },
    {
        path: "/not-found-item",
        component: NotFound,
    },
    {
        path: '/:cathAll(.*)',
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: "active_link",
    routes,
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    next()
});

export default router;