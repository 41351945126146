<template>
    <div class="box" dir="auto">
        <div class="content ">
            <div class=" d-flex justify-content-start px-4 py-1 mb-5">
                <p class="mb-0">{{ $t('you have an account ?') }}</p>
                <router-link to="/" class="text-decoration-none text-black">
                    <p class="mb-0 mx-2">{{ $t('Login') }}</p>
                </router-link>
            </div>
            <div class="formBox">
                <h5 class="text-center color mb-3 mt-3">{{ $t("Create Account") }}</h5>
                <register-component></register-component>
            </div>
        </div>
        <div class="image">
            <img class="logoregister" src="@/assets/images/register/Image.png" alt="RegisterLogo" />
            <!-- JSKY Logo -->
            <img src="@/assets/images/logo/logo1.png" class="logoimage" alt="logo1" />
            <!-- Text -->
            <div class="text-box">
                <h2 class="text-white mb-3">{{ $t("Create Account") }}</h2>
                <h5 class="text-white">
                    {{ $t("Create an account as a business account") }}
                </h5>
            </div>
            <!-- Proplem -->
            <!-- <div class="problem">
                <router-link to="#" class="text-decoration-none text-white">
                    {{ $t("there is a problem ? Connect with us") }}
                </router-link>
            </div> -->
        </div>
    </div>
</template>
<script>
import RegisterComponent from '@/components/business/rigester/RegisterComponent.vue';
export default {
    name: "RegisterView",
    components: { RegisterComponent },
};
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    height: 100vh;
    // overflow: hidden;
    // background-color: red;
    display: flex;
    justify-content: center;

    .image {
        width: 35%;
        height: 100%;
        position: relative;

        .logoregister {
            width: 100% !important;
            height: 100% !important;
        }

        .logoimage {
            width: 270px;
            position: absolute;
            top: 10%;
            left: 50%;
            transform: translateX(-50%);
        }

        .text-box {
            width: 100%;
            position: absolute;
            top: 24%;
            text-align: center;
            padding: 1rem;
            left: 0%;
        }

        .problem {
            width: 100%;
            position: absolute;
            bottom: 5%;
            text-align: left;
            padding: 1rem;
            left: 0%;
        }
    }

    .content {
        width: 65%;
        height: 100%;
        // background-color: green;
        padding: 1rem;

        .formBox {
            // border: 1px solid red;
            border-radius: 8px;
            padding: 1rem;
            width: 70%;
            box-shadow: rgb(0 0 0 / 15%) 0px 1px 5px;
            margin: 2.8rem auto;
        }
    }
}

@media screen and (max-width: 992px) {
    .box {
        flex-direction: column !important;
        height: auto !important;

        .image {
            width: 100% !important;
            height: auto !important;
        }

        .content {
            width: 100%;
            height: auto !important;
        }

        .formBox {
            width: 98% !important;
        }
    }
}

</style>
