<template>
    <div class="back color_black" dir="auto">
        <div class="overlay"></div>
        <div class="boxcontainer">
            <h2 class="text-center mt-4 mb-3">{{ $t('You are Invited ti Use Jsky') }}</h2>
            <p class="text-muted text-center">{{ $t('rs4it now uses FlyAkeed fir Business to help uou book your next easier than ever!') }}</p>
            <div class="container">
                <h5>{{ $t('Personal Information') }}</h5>
                <div class="row mt-4">
                    <div class="col-lg-4">
                        <label for="" class="form-label text-muted d-block mb-3">{{ $t('Title') }}</label>
                        <select v-model="Emploey_data.title" class="select w-100 " >
                            <option value="mr">{{ $t('Mr') }}</option>
                            <option value="miss">{{ $t('Miss') }}</option>
                        </select>
                    </div>
                    <div class="col-lg-4">
                        <label for="" class="form-label text-muted mb-3">{{ $t('First Name') }}</label>
                        <input type="text" class="form-control mt-0" v-model="Emploey_data.first_name">
                    </div>
                    <div class="col-lg-4">
                        <label for="" class="form-label text-muted mb-3">{{ $t('Last Name') }}</label>
                        <input type="text" class="form-control mt-0" v-model="Emploey_data.last_name">
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-lg-4">
                        <label for="" class="form-label text-muted  mb-3">{{ $t('email') }}</label>
                        <input type="email" class="form-control mt-0" v-model="Emploey_data.email">
                    </div>
                    <div class="col-lg-4">
                        <label for="exampleDataListCountryCode" class="form-label text-muted  mb-3">{{ $t('Country')
                            }}</label>
                        <div class="dropdown ">
                            <input type="text" @keyup="ChangeInCountryList" class="form-control"
                                id="dropdownMenuButtonCountry" data-bs-toggle="dropdown" aria-expanded="false">
                            <div class="showCountryItem" v-if="CountryItem != null">
                                <span>
                                    <i class="mx-2" :class="`flag flag-${CountryItem.code}`"></i>
                                    <span dir="ltr">{{ CountryItem.dial_code }}</span>
                                </span>
                            </div>
                            <ul class="dropdown-menu dropdown-menu-country" aria-labelledby="dropdownMenuButtonCountry">
                                <li @click="SetCountry(item)"
                                    class="lifromcountry pointer d-flex justify-content-between align-items-center"
                                    v-for="item in countriesInSelect" :key="item">
                                    <span>
                                        {{ $i18n.locale == 'en' ? item.en_name : item.name }}
                                    </span>
                                    <span>
                                        <span style="margin: 0 5px;" dir="ltr">{{ item.dial_code }}</span>
                                        <i :class="`flag flag-${item.code}`"></i>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <label for="" class="form-label text-muted mb-3">{{ $t('phone') }}</label>
                        <input :readonly="CountryItem == null" type="text" class="form-control mt-0"
                            v-model="Emploey_data.phone">
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-lg-8">
                        <label for="" class="form-label d-block text-muted  mb-3">{{ $t('nationality') }}</label>

                        <el-select class="d-block mb-2" v-if="$i18n.locale == 'en'" dir="ltr" filterable
                            :placeholder="$t('search')" reserve-keyword v-model="Emploey_data.nationality" size="large">
                            <el-option v-for=" item in nationalitiesArray" :key="item" :label="item.en_label"
                                :value="item.value" />
                        </el-select>
                        <el-select class="d-block mb-2" v-else :placeholder="$t('search')" filterable
                            v-model="Emploey_data.nationality" reserve-keyword size="large">

                            <el-option style="text-align: right;" v-for=" item in nationalitiesArray" :key="item"
                                :label="item.ar_label" :value="item.value" />

                        </el-select>
                    </div>
                    <div class="col-lg-4 ">
                        <label for="" class="form-label text-muted  mb-3">{{ $t('birth date') }}</label>
                        <input class="form-control" type="date"  v-model="Emploey_data.birth_date">
                    </div>
                </div>

                <h5 class="mt-5">{{ $t('Travel Documents') }}</h5>

                <div class="passportbox mb-4  mt-3">
                    <div @click="showboxpassport = !showboxpassport"
                        class="passheader pointer py-3 d-flex justify-content-between align-items-center px-4">
                        <h6 class="mb-0">{{ $t('Passport information') }}</h6>
                        <button type="button" class="btn btn-close" aria-label="close"></button>
                    </div>
                    <div class="row pb-4 px-3 mt-4" v-if="showboxpassport">
                        <div class="col-lg-4">
                            <label for="" class="form-label text-muted mb-3">{{ $t('Passport Number') }}</label>
                            <input type="text" class="form-control mt-0" v-model="Emploey_data.passport_number">
                        </div>
                        <div class="col-lg-4">
                            <label for="" class="form-label d-block mb-3 text-muted">{{ $t('Issuing country') }}</label>
                            <el-select class="d-block mb-2" v-if="$i18n.locale == 'en'" dir="ltr" filterable
                                :placeholder="$t('search')" reserve-keyword
                                v-model="Emploey_data.passport_issuing_country" size="large">
                                <el-option v-for=" item in Jsoncountries" :key="item" :label="item.en_label"
                                    :value="item.value" />
                            </el-select>
                            <el-select class="d-block mb-2" v-else :placeholder="$t('search')" filterable
                                v-model="Emploey_data.passport_issuing_country" reserve-keyword size="large">

                                <el-option style="text-align: right;" v-for=" item in Jsoncountries" :key="item"
                                    :label="item.ar_label" :value="item.value" />

                            </el-select>
                        </div>
                        <div class="col-lg-4">
                            <label for="" class="form-label text-muted mb-3">{{ $t('Release Date') }}</label>
                            <input class="form-control" type="date"  v-model="Emploey_data.passport_expiry_date">

                        </div>
                    </div>
                </div>

                <div class="passportbox mb-4 mt-3">
                    <div @click="showboxindity = !showboxindity"
                        class="passheader pointer py-3 d-flex justify-content-between align-items-center px-4">
                        <h6 class="mb-0">{{ $t('ID information') }}</h6>
                        <button type="button" class="btn btn-close" aria-label="close"></button>
                    </div>
                    <div class="row pb-4 px-3 mt-4" v-if="showboxindity">
                        <div class="col-lg-4">
                            <label for="" class="form-labeltext-muted  mb-3">{{ $t('ID Number') }}</label>
                            <input type="text" class="form-control mt-0" v-model="Emploey_data.IDNumber">
                        </div>
                        <div class="col-lg-4">
                            <label for="" class="form-label text-muted mb-3">{{ $t('Issuing country') }}</label>
                            <el-select class="d-block mb-2" v-if="$i18n.locale == 'en'" dir="ltr" filterable
                                :placeholder="$t('search')" reserve-keyword v-model="Emploey_data.id_issuing_country"
                                size="large">
                                <el-option v-for=" item in Jsoncountries" :key="item" :label="item.en_label"
                                    :value="item.value" />
                            </el-select>
                            <el-select class="d-block mb-2" v-else :placeholder="$t('search')" filterable
                                v-model="Emploey_data.id_issuing_country" reserve-keyword size="large">

                                <el-option style="text-align: right;" v-for=" item in Jsoncountries" :key="item"
                                    :label="item.ar_label" :value="item.value" />

                            </el-select>
                        </div>
                        <div class="col-lg-4">
                            <label for="" class="form-label text-muted mb-3">{{ $t('Release Date') }}</label>
                            <input class="form-control" type="date"  v-model="Emploey_data.id_expiry_date">

                        </div>
                    </div>
                </div>

                <div class="mt-4 fw-bold">
                    <span>
                        {{ $t('By Clicking Active Account you Agree to') }}
                        <span style="color: #D1Af77;">{{ $t('jsky Terms of Use') }}</span>
                        {{ $t('and acknowledged yoy have read') }}
                        <span style="color: #D1Af77;">{{ $t('Privacy Policy') }}</span>
                    </span>
                </div>

                <div class="mt-4 mb-4">
                    <button @click="SumbmitForm"
                        :disabled="Emploey_data.phone == '' || CountryItem == null || Emploey_data.first_name == '' || Emploey_data.last_name == '' || Emploey_data.email == '' || Emploey_data.nationality == '' || Emploey_data.birth_date == null"
                        style="background-color: #D1Af77;" class="btn text-white w-100 text-center py-2 color-bg">
                        <span v-if="!loadingaddnew">{{ $t('Activate Account') }}</span>
                        <span v-else>{{ $t('Loading...') }}</span>
                    </button>
                </div>

            </div>

        </div>
    </div>
</template>
<script>
import country_code from "@/assets/files/country-code.json"
import nationalities from "@/assets/files/nationalities.json"
import Jsoncountries from "@/assets/files/countries.json"
import employeeService from '@/services/business/company/employee-service';
import { useRoute } from 'vue-router';
import { toast } from "vue3-toastify";
export default {
    name: "inviteUserView",
    components: {},
    data() {
        return {
            route: useRoute(),
            loadingaddnew: false,
            Emploey_data: {
                lang: localStorage.getItem('lang'),
                title: 'mr',
                first_name: '',
                last_name: '',
                email: '',
                nationality: '',
                birth_date: null,
                phone_country_code: '',
                phone: '',
                grade: null,
                job_title: null,
                branche_id: null,
                department_id: null,
                costCenter_id: null,
                unit_id: null,
                manger_number: null,
                employee_number: null,
                employed_date: null,
                division_id: null,
                subsidiary_id: null,
                IDNumber: null,
                id_issuing_country: [],
                id_expiry_date: null,
                passport_number: null,
                passport_issuing_country: [],
                passport_expiry_date: null,
                passengers: []
            },
            Jsoncountries: Jsoncountries,

            allcountries: country_code,
            countries: [],
            countriesInSelect: [],
            CountryItem: null,
            nationalitiesArray: nationalities,
            showboxpassport: true,
            showboxindity: true,
        }
    },
    methods: {
        ChangeInCountryList(e) {
            this.CountryItem = null
            if (e.target.value != '') {
                let array = this.countries
                if (this.lang == 'en') {
                    this.countriesInSelect = array.filter(country => country.en_name.includes(e.target.value))
                } else {
                    this.countriesInSelect = array.filter(country => country.name.includes(e.target.value))
                }
            } else {
                this.countriesInSelect = this.countries
            }
        },
        SetCountry(item) {
            this.CountryItem = item
            document.getElementById('dropdownMenuButtonCountry').value = ''
        },


        async SumbmitForm() {
            this.loadingaddnew = true
            this.CountryItem ? this.Emploey_data.phone_country_code = this.CountryItem.dial_code : ''

            this.Emploey_data.passengers = JSON.stringify(this.Emploey_data.passengers)

            let response = null
            await employeeService.Invitaion_Stor(this.route.params.id, this.Emploey_data).then(res => {
                response = res
            })

            toast.success(response.data.message)
            await new Promise(r => setTimeout(r, 1000));


            this.loadingaddnew = false
        }
    },
    created() {
        const arrayOfObj = Object.entries(country_code).map((e) => (e[1]));
        this.countries = arrayOfObj
        this.countriesInSelect = arrayOfObj
    }
}
</script>

<style lang="scss" scoped>

@media screen and (max-width: 992px) {
    .back {
        min-height: 250vh !important;
    }
    .boxcontainer {
        width: 94% !important;
        left: 3% !important;
    }
}

.back {
    min-height: 170vh;
    background-color: #272E35 !important;
    position: relative;

    .overlay {
        background-color: #D1Af77;
        height: 100px;
    }

    .boxcontainer {
        background-color: white;
        height: auto !important;
        position: absolute;
        top: 50px;
        width: 70%;
        left: 15%;
        padding: 0 1rem;
    }

    .select {
        height: 43px;
        background-color: transparent;
        border: 1px solid #ced4da ;
    }

    .form-control {
        border-radius: 0 !important;
    }
}

.dropdown-menu-country{
    max-height: 300px;
    border-radius: 0;
    overflow-y: auto;
    width: 100% !important;

    .lifromcountry {
        margin: 5px 0;
        padding: 5px 10px;

        &:hover {
            background-color: rgba(128, 128, 128, 0.477);
        }
    }
}

.showCountryItem {
    position: absolute;
    background-color: transparent;
    width: 150px;
    height: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.passportbox {
    border: 1px solid #ced4da !important;

    .passheader {
        border-bottom: 1px solid #ced4da !important;
    }
}
</style>