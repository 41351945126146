import Api from "@/api/api";
import authHeader from "@/services/business/auth/auth-header";
const END_POINT = "api/business/";
class NotificationService {
    get_user_notifications() {
        return Api.get(END_POINT + "get_user_notifications", { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
}
export default new NotificationService();