import axios from "axios";
import Swal from 'sweetalert2';

let unauthorizedAlerted = false;
const Api = axios.create({
    baseURL: "https://jsky.rs4it.com/api_v2/public/",
});

Api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }
        if (!navigator.onLine) {
            alert("No internet connection");
        }
        if (error.response) {
            if (error.response.status >= 500 && error.response.status <= 599) {
                alert("Server Error");
            }
            if (error.response.status === 401 && !unauthorizedAlerted) {
                unauthorizedAlerted = true;
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops! Unauthorized access.',
                    text: 'The session has expired, please log back in',
                }).then((result) => {
                    if (result.isConfirmed) {
                        localStorage.removeItem("business_token");
                        window.location.href = '/login';
                    }
                });
            } else if (error.response.status === 404) {
                window.location.pathname = "/not-found-item";
            } else {
                console.log("HTTP Error:", error.response.status, error.response.data);
            }
        } else if (error.request && !unauthorizedAlerted) {
            unauthorizedAlerted = true;
            Swal.fire({
                icon: 'error',
                title: 'Network Error',
                text: 'Please make sure you are connected to the internet.',
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });
        } else {
            console.error("Error:", error);
        }
        return Promise.reject(error);
    }
);
export default Api;