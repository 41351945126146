<template>
  <main id="app">
    <Preloader />
    <router-view />
    <Chat />
  </main>
</template>
<script>
import notificationService from "@/services/business/notifications/notification-service"
import Chat from "@/components/business/layout/ChatBox.vue"
import Preloader from "@/components/business/layout/PreloaderComponent.vue"
export default {
  components: { Chat, Preloader },
  data() {
    return {
      business_token: localStorage.getItem('business_token') ? true : false,
    };
  },
  methods: {
    async get_user_notifications() {
      await notificationService.get_user_notifications().then(res => {
        if (res.data.status == 1) {
          this.$store.commit(
            "notification/SET_ALL_NOTIFICATION",
            res.data.notifications
          );
        }
      })
    },
  },
  created() {
    this.business_token ? this.get_user_notifications() : ''
  },
};
</script>

<style lang="scss">
@import "./assets/style/index";
@import "./assets/style/index.defer.min.css";
@import "./assets/style/airlines-logo.css";
.light-icon-eye {
    cursor: pointer !important;
}
</style>
