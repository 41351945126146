<template>
    <div id="sidebar" class="sidebar" :class="$i18n.locale == 'en' ? '' : 'sidebar_ar'">
        <div class="text-center close_div">
            <button @click="toogleshow" class="btn btn-close" aria-label="close"></button>
        </div>
        <!-- Logo -->
        <div class="logo d-flex justify-content-center pt-3">
            <img src="@/assets/images/logo/logo1.png" class="logoimage"
                alt="logo1">
        </div>
        <!-- New Reservation -->
        <div class="mt-3 d-flex justify-content-center">
            <button @click="$router.push({ name: 'new-booking' })" class="btn special_btn">
                <span :class="$i18n.locale == 'en' ? 'me-1' : 'visually-hidden'">+</span>
                {{ $t('new-booking') }}
                <span :class="$i18n.locale == 'en' ? 'visually-hidden' : 'me-1'">+</span>
            </button>
        </div>

        <!-- Menue -->
        <div class="menuBox mt-3 py-2">
            <h6 class="title">{{ $t('menu') }}</h6>
            <ul class="list-unstyled accordion accordion-flush p-0" id="accordionFlushExample">

                <!-- Dashboard -->
                <router-link to="/dashboard" class="text-decoration-none  text-white">
                    <li class="li mb-1">
                        <div class="d-flex align-items-center">
                            <img src="@/assets/images/sidebar/dashboard.png"
                                :class="$i18n.locale == 'en' ? 'me-3' : 'ms-3'" width="20" alt="dashboard">
                            <span>{{ $t('dashboard') }}</span>
                        </div>
                    </li>
                </router-link>

                <!-- Booking -->
                <router-link :to="{ name: 'booking-requests' }" class="text-decoration-none  text-white">
                    <li class="li mb-1">
                        <div class="d-flex align-items-center">
                            <img src="@/assets/images/sidebar/users.png"
                                :class="$i18n.locale == 'en' ? 'me-3' : 'ms-3'" width="20" height="20" alt="dashboard">
                            <span>{{ $t('booking-requests') }}</span>
                        </div>
                    </li>
                </router-link>
                <!-- Reservation -->
                <div class="text-decoration-none text-white accordion-item ">
                    <li @click="flipImage('calenderarrow')" :class="route.includes('flights') ? '' : 'collapsed'"
                        class="li mb-1 accordion-button " data-bs-toggle="collapse"
                        data-bs-target="#flushcalender-collapseOne"
                        :aria-expanded="route.includes('flights') ? true : false"
                        aria-controls="flushcalender-collapseOne">
                        <div class="d-flex align-items-center w-75">
                            <img src="@/assets/images/sidebar/calender.png"
                                :class="$i18n.locale == 'en' ? 'me-3' : 'ms-3'" width="20" alt="calender">
                            <span>{{ $t('Reservations') }}</span>
                        </div>
                        <div >
                            <img id="calenderarrow" :style="route.includes('flights') ? 'transform: rotate(90deg)' : ''"
                                src="@/assets/images/sidebar/arrow.png"
                                :class="$i18n.locale == 'en' ? 'me-4' : 'me-4 rotata_ar'" width="15" alt="arrow">
                        </div>
                    </li>
                    <div id="flushcalender-collapseOne" class="accordion-collapse  py-0  collapse"
                        aria-labelledby="flush-headingOne" :class="route.includes('flights') ? 'show' : ''"
                        data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body  py-0">
                            <router-link :to="{ name: 'flights' }" class="text-decoration-none text-white">
                                <li class="innerli">
                                    <div class="d-flex align-items-center">
                                        <span>{{ $t('flights') }}</span>
                                    </div>
                                </li>
                            </router-link>
                        </div>
                    </div>
                </div>
                <!-- Company -->
                <div class="text-decoration-none text-white accordion-item">
                    <li @click="flipImage('companyarrow')" class="li mb-1 accordion-button"
                        :class="route.includes('company') ? '' : 'collapsed'" data-bs-toggle="collapse"
                        data-bs-target="#flushcompany-collapseOne"
                        :aria-expanded="route.includes('company') ? true : false"
                        aria-controls="flushcompany-collapseOne">
                        <div class="d-flex align-items-center w-75">
                            <img src="@/assets/images/sidebar/company.png"
                                :class="$i18n.locale == 'en' ? 'me-3' : 'ms-3'" width="20" alt="company">
                            <span>{{ $t('company') }}</span>
                        </div>
                        <div >
                            <img id="companyarrow" :style="route.includes('company') ? 'transform: rotate(90deg)' : ''"
                                src="@/assets/images/sidebar/arrow.png"
                                :class="$i18n.locale == 'en' ? 'me-4' : 'me-4 rotata_ar'" width="15" alt="arrow">
                        </div>
                    </li>
                    <div id="flushcompany-collapseOne" class="accordion-collapse  py-0  collapse"
                        :class="route.includes('company') ? 'show' : ''" aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body  py-0">
                            <router-link :to="{ name: 'employees' }" class="text-decoration-none text-white">
                                <li class="innerli">
                                    <div class="d-flex align-items-center">
                                        <span>{{ $t('employees') }}</span>
                                    </div>
                                </li>
                            </router-link>
                            <router-link :to="{ name: 'groups' }" class="text-decoration-none text-white">
                                <li class="innerli">
                                    <div class="d-flex align-items-center">
                                        <span>{{ $t('groups') }}</span>
                                    </div>
                                </li>
                            </router-link>
                            <router-link :to="{ name: 'subsidiaries' }" class="text-decoration-none text-white">
                                <li class="innerli">
                                    <div class="d-flex align-items-center">
                                        <span>{{ $t('subsidiaries') }}</span>
                                    </div>
                                </li>
                            </router-link>
                            <router-link :to="{ name: 'units' }" class="text-decoration-none text-white">
                                <li class="innerli">
                                    <div class="d-flex align-items-center">
                                        <span>{{ $t('units') }}</span>
                                    </div>
                                </li>
                            </router-link>
                            <router-link :to="{ name: 'divisions' }" class="text-decoration-none text-white">
                                <li class="innerli">
                                    <div class="d-flex align-items-center">
                                        <span>{{ $t('divisions') }}</span>
                                    </div>
                                </li>
                            </router-link>
                            <router-link :to="{ name: 'departments' }" class="text-decoration-none text-white">
                                <li class="innerli">
                                    <div class="d-flex align-items-center">
                                        <span>{{ $t('departments') }}</span>
                                    </div>
                                </li>
                            </router-link>
                            <router-link :to="{ name: 'branches' }" class="text-decoration-none text-white">
                                <li class="innerli">
                                    <div class="d-flex align-items-center">
                                        <span>{{ $t('branches') }}</span>
                                    </div>
                                </li>
                            </router-link>
                            <router-link :to="{ name: 'cost-centers' }" class="text-decoration-none text-white">
                                <li class="innerli">
                                    <div class="d-flex align-items-center">
                                        <span>{{ $t('cost-centers') }}</span>
                                    </div>
                                </li>
                            </router-link>
                            <router-link :to="{ name: 'company-account' }" class="text-decoration-none text-white">
                                <li class="innerli">
                                    <div class="d-flex align-items-center">
                                        <span>{{ $t('company-account') }}</span>
                                    </div>
                                </li>
                            </router-link>
                        </div>
                    </div>
                </div>

                <!-- Invoices -->
                <div v-if="0" class="text-decoration-none text-white accordion-item">
                    <li @click="flipImage('fatoraarrow')" class="li mb-1 accordion-button"
                        :class="route.includes('invoice') ? '' : 'collapsed'" data-bs-toggle="collapse"
                        data-bs-target="#flushfatora-collapseOne"
                        :aria-expanded="route.includes('invoice') ? true : false"
                        aria-controls="flushfatora-collapseOne">
                        <div class="d-flex align-items-center w-75">
                            <img src="@/assets/images/sidebar/fatora.png"
                                :class="$i18n.locale == 'en' ? 'me-3' : 'ms-3'" width="20" alt="fatora">
                            <span>{{ $t('Invoices') }}</span>
                        </div>
                        <div >
                            <img id="fatoraarrow" :style="route.includes('invoice') ? 'transform: rotate(90deg)' : ''"
                                src="@/assets/images/sidebar/arrow.png"
                                :class="$i18n.locale == 'en' ? 'me-4' : 'me-4 rotata_ar'" width="15" alt="arrow">
                        </div>
                    </li>
                    <div id="flushfatora-collapseOne" class="accordion-collapse  py-0  collapse"
                        aria-labelledby="flush-headingOne" :class="route.includes('invoice') ? 'show' : ''"
                        data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body  py-0">
                            <router-link :to="{ name: 'iteminvoice1' }" class="text-decoration-none text-white">
                                <li class="innerli">
                                    <div class="d-flex align-items-center">
                                        <span>{{ $t('The most prominent indicators') }}</span>
                                    </div>
                                </li>
                            </router-link>
                            <router-link :to="{ name: 'iteminvoice1' }" class="text-decoration-none text-white">
                                <li class="innerli">
                                    <div class="d-flex align-items-center">
                                        <span>{{ $t('Processes') }}</span>
                                    </div>
                                </li>
                            </router-link>
                        </div>
                    </div>
                </div>

                <!-- Setting -->
                <div class="text-decoration-none text-white accordion-item">
                    <li @click="flipImage('settingarrow')" class="li mb-1 accordion-button "
                        :class="route.includes('setting') ? '' : 'collapsed'" data-bs-toggle="collapse"
                        data-bs-target="#flushsetting-collapseOne"
                        :aria-expanded="route.includes('setting') ? true : false"
                        aria-controls="flushsetting-collapseOne">
                        <div class="d-flex align-items-center w-75">
                            <img src="@/assets/images/sidebar/setting.png"
                                :class="$i18n.locale == 'en' ? 'me-3' : 'ms-3'" width="20" alt="setting">
                            <span>{{ $t('setting') }}</span>
                        </div>
                        <div >
                            <img id="settingarrow" :style="route.includes('setting') ? 'transform: rotate(90deg)' : ''"
                                src="@/assets/images/sidebar/arrow.png"
                                :class="$i18n.locale == 'en' ? 'me-4' : 'me-4 rotata_ar'" width="15" alt="arrow">
                        </div>
                    </li>
                    <div id="flushsetting-collapseOne" class="accordion-collapse  py-0  collapse"
                        :class="route.includes('setting') ? 'show' : ''" aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body  py-0">
                            <router-link :to="{ name: 'policies' }" class="text-decoration-none text-white">
                                <li class="innerli">
                                    <div class="d-flex align-items-center">
                                        <span>{{ $t('policies') }}</span>
                                    </div>
                                </li>
                            </router-link>
                            <router-link :to="{ name: 'travel-purposes' }" class="text-decoration-none text-white">
                                <li class="innerli">
                                    <div class="d-flex align-items-center">
                                        <span>{{ $t('travel-purposes') }}</span>
                                    </div>
                                </li>
                            </router-link>
                            <router-link :to="{ name: 'employee-grades' }" class="text-decoration-none text-white">
                                <li class="innerli">
                                    <div class="d-flex align-items-center">
                                        <span>{{ $t('employee-grades') }}</span>
                                    </div>
                                </li>
                            </router-link>
                            <router-link :to="{ name: 'payment-methods' }" class="text-decoration-none text-white">
                                <li class="innerli">
                                    <div class="d-flex align-items-center">
                                        <span>{{ $t('payment-methods') }}</span>
                                    </div>
                                </li>
                            </router-link>
                            <router-link :to="{ name: 'users' }" class="text-decoration-none text-white">
                                <li class="innerli">
                                    <div class="d-flex align-items-center">
                                        <span>{{ $t('users') }}</span>
                                    </div>
                                </li>
                            </router-link>
                        </div>
                    </div>
                </div>
            </ul>
        </div>
    </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { watchEffect } from 'vue'
export default {
    name: "SidebarComponent ",
    data() {
        return {
            route: useRoute().path,
            imageflip: ""
        }
    },
    methods: {
        flipImage(id) {
            if (this.imageflip != "") {
                document.getElementById(this.imageflip).classList.toggle('rotate')
            }
            this.imageflip = id
            if (document.getElementById(id).getAttribute('style') != 'null') {
                if (document.getElementById(id).getAttribute('style') == '') {
                    document.getElementById(id).classList.toggle('rotate')
                } else {
                    document.getElementById(id).setAttribute('style', null)
                }
            } else {
                document.getElementById(id).classList.toggle('rotate')
            }
        },
        toogleshow() {
            document.getElementById('sidebar').classList.toggle('d-none')
        }
    },
    mounted() {
        const route = useRoute();
        watchEffect(() => {
            if (route.name != undefined) {
                this.route = route.path;
            }
        });
    }
}
</script>

<style lang="scss" scoped>

.btn-close {
    background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat !important;
    opacity: 1 !important;
    filter: brightness(0) invert(1);
    width: 0.5rem;
    height: 0.5rem;
}

@media screen and (max-width: 992px) {
    .sidebar {
        position: absolute;
        z-index: 999 !important;
    }

    .close_div {
        display: block !important;
    }
}

.close_div {
    display: none;
}

.rotata_ar {
    transform: rotate(180deg)
}
.rotate {
    transform: rotate(90deg) !important;
}

.sidebar {
    // border-top-right-radius: 20px;
    // border-bottom-right-radius: 20px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.sidebar_ar {
    // border-top-left-radius: 20px;
    // border-bottom-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    direction: rtl;
    right: 0;
}
.sidebar {
    background-color: #323940;
    box-shadow: 18px 4px 35px 0px #00000005;
    z-index: 5;
    color: white;
    overflow-y: hidden;
    width: 260px;
    height: 100vh;
    position: fixed;
    top: 0;
    
    overflow-x: hidden;
    .logoimage {
        width: 175px;
        cursor: pointer;
    }

    .special_btn {
        background-color: #D1AF77;
        color: white;
        padding: 0.5rem 2rem;
        border-radius: 20px !important;
        font-weight: 600;
        font-size: 17px;
    }

    .menuBox {
        background-color: #2F363D;
        height: 600px;
        // border-radius: 50px;
        overflow-y: auto;

        .title {
            color: white;
            opacity: 0.50;
            padding: 0.1rem 1.25rem;
        }
        
        .innerli {
            padding: 0.7rem 3.4rem !important;
        }
        .li, .innerli {
            // background-color: white;
            padding: 0.7rem 0.6rem;
            display: flex !important;
            align-items: center;
            justify-content: space-between !important;
            opacity: 0.60;
            font-size: 14px;
            
            &.active {
                background-image: linear-gradient(to right,#D1AF77, #323940);
                opacity: 1 !important;
            }
            &:hover {
                background-image: linear-gradient(to right,#D1AF77, #323940);
                opacity: 1 !important;
            }

        }

        .accordion {
            --bs-accordion-btn-icon: none !important;
            --bs-accordion-btn-active-icon: none !important;
        }
        .accordion-item, .accordion-button {
            background-color: #2F363D !important;
            border: none !important;
            color: white !important;
            
        }
        
        .accordion-button {
            justify-content: end !important;
        }

        .accordion-button:not(.collapsed) {
            box-shadow: none !important;
        }

        .accordion-collapse {
            border: none !important;
        }
        .accordion-button::after {
            width: auto;
        }
    }
}
</style>