function load(component) {
    return () =>
        import (`@/views/business/invoice/${component}.vue`);
}
const InvoiceRoutes = {
    path: "/invoice",
    alwaysShow: true,
    children: [{
        path: "iteminvoice1",
        name: "iteminvoice1",
        component: load("InvoiceView"),
        meta: { title: "Invoice", noCache: true },
    }]
};
export default InvoiceRoutes;