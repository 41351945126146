<template>
    <div class="box" dir="auto">
        <div class="content ">
            <div class=" d-flex justify-content-start text-ingo px-4 py-1 mb-5">
                <p class="mb-0">{{ $t('Do not have an account?') }}</p>
                <router-link to="/register" class="text-decoration-none text-black">
                    <p class="mb-0 mx-2">{{ $t('Create a new account') }}</p>
                </router-link>
            </div>
            <div class="formBox">
                <form @submit.prevent="resetpass">
                    <h5 class="text-center mb-4 mt-3">{{ $t('Forgot your password') }}</h5>
                    <div class="mb-4">
                        <label for="" class="mb-2" style="color: #84818A;">{{ $t('email') }}</label>
                        <input type="email" required class="form-control" v-model="email">
                    </div>
                    <div class="text-center">
                        <button type="submit" :disabled="email == ''" class="btn px-5 mt-4 text-white"
                            style="background-color: #D1AF77;border-color: #D1AF77;">
                            <p class=" mb-0 ">{{ $t('Password recovery') }}</p>
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="image">
            <img class="logoregister" src="@/assets/images/register/login.png" alt="RegisterLogo" />
            <!-- JSKY Logo -->
            <img src="@/assets/images/logo/logo1.png" class="logoimage" alt="logo1" />
            <!-- Text -->
            <div class="text-box">
                <h2 class="text-white mb-3">{{ $t("Business login") }}</h2>
                <h5 class="text-white">
                    {{ $t("Forgot your password") }}
                </h5>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import { toast } from "vue3-toastify";
import authenticationServices from '@/services/business/auth/auth-service';
import LvLoader from 'lightvue/loaders';
export default {
    name: "ForgetPasswordView",
    components: { LvLoader },
    data() {
        return {
            lang: localStorage.getItem('lang')
        }
    },
    methods: {
        async resetpass() {
            $(".pre-loader").show();
            let data = {
                email: this.email,
                lang: this.lang
            }
            await authenticationServices.forgot_password(data).then(res => {
                $(".pre-loader").hide();
                if (res.status == 0) {
                    toast.error(res.message)
                } else {
                    this.$swal(this.$t("Forget password"), res.data.message, "success");
                    this.$router.push({ name: "login" });
                }
            })
        }
    }
};
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    height: 100vh;
    // background-color: red;
    display: flex;
    justify-content: center;

    .image {
        width: 35%;
        height: 100%;
        position: relative;

        .logoregister {
            width: 100% !important;
            height: 100% !important;
        }

        .logoimage {
            width: 270px;
            position: absolute;
            top: 10%;
            left: 50%;
            transform: translateX(-50%);
        }

        .text-box {
            width: 100%;
            position: absolute;
            top: 24%;
            text-align: center;
            padding: 1rem;
            left: 0%;
        }

        .problem {
            width: 100%;
            position: absolute;
            bottom: 5%;
            text-align: left;
            padding: 1rem;
            left: 0%;
        }
    }

    .content {
        width: 65%;
        height: 100%;
        // background-color: green;
        padding: 1rem;

        .formBox {
            // border: 1px solid red;
            border-radius: 8px;
            padding: 2rem 2rem;
            width: 55%;
            min-height: 224px;
            //   box-shadow: 0px 12px 23px 0px #d1af7797;

            box-shadow: rgb(0 0 0 / 15%) 0px 1px 5px;
            margin: 12rem auto;
        }
    }
}

@media screen and (max-width: 992px) {

    .text-ingo {
        display: block !important;
    }

    .box {
        flex-direction: column !important;
        height: auto !important;

        .image {
            width: 100% !important;
            height: auto !important;
        }

        .content {
            width: 100%;
            height: auto !important;
        }

        .formBox {
            margin: 1rem auto !important;
            width: 98% !important;
        }
    }
}

.animatetohide {
    animation: hideen 2s;
    -webkit-animation: hideen 2s;
    -moz-animation: hideen 2s;
    -o-animation: hideen 2s;
    -ms-animation: hideen 2s;
    animation-duration: 2s;
}

@keyframes hideen{
    0%{
        transform: rotateY(0deg);
    }
    100%{
        transform: rotateY(-90deg);
    }
}

.rotatehidden {
    transform: rotateY(-90deg);
}

.animatetoshow {
    animation: show 2s;
    -webkit-animation: show 2s;
    -moz-animation: show 2s;
    -o-animation: show 2s;
    -ms-animation: show 2s;
    animation-duration: 2s;
}
@keyframes show{
    0%{
        transform: rotateY(-90deg);
    }
    100%{
        transform: rotateY(0deg);
        
    }
}
</style>
