import Arairports from "@/assets/files/ar-airports.json"
import Enairports from "@/assets/files/en-airports.json"
const lang = localStorage.getItem("lang") || "en";
const GetInfoFromAirports = {
    methods: {
        GetCountry(value) {
            let country = null
            if(lang == 'ar') {
                Arairports.forEach(item => {
                    if(item.code == value) {
                        country = item.country
                    }
                });
            }else {
                Enairports.forEach(item => {
                    if(item.code == value) {
                        country = item.country
                    }
                });
            }
            
            return country
        },
        GetAirport(value) {
            let Airport = null
            if(lang == 'ar') {
                Arairports.forEach(item => {
                    if(item.code == value) {
                        Airport = item.name
                    }
                });
            }else {
                Enairports.forEach(item => {
                    if(item.code == value) {
                        Airport = item.name
                    }
                });
            }
            
            return Airport
        },
        GetCity(value) {
            let city = null
            if(lang == 'ar') {
                Arairports.forEach(item => {
                    if(item.code == value) {
                        city = item.city
                    }
                });
            }else {
                Enairports.forEach(item => {
                    if(item.code == value) {
                        city = item.city
                    }
                });
            }
            
            return city
        },
    }
}

export default GetInfoFromAirports;