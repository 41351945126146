function load(component) {
    return () =>
        import (`@/views/group/${component}.vue`);
}

const groupguest = (to, from, next) => {
    if (!localStorage.getItem("group_token")) {
        return next();
    } else {
        return next("/group/booking/new");
    }
};

const groupauth = (to, from, next) => {
    if (localStorage.getItem("group_token")) {
        return next();
    } else {
        return next("/group/login");
    }
};

const GroupAuth = {
    path: "/group",
    alwaysShow: true,
    children: [{
            path: "login",
            name: "group-login",
            component: load("LoginView"),
            meta: { title: "GroupAuth", noCache: true },
            beforeEnter: groupguest,
        },
        {
            path: "booking/new",
            name: "group-new-booking",
            component: load("NewBookingView"),
            meta: { title: "Group Dashboard Add Reservation", noCache: true },
            beforeEnter: groupauth,
        }, 
         {
            path: "flight-result/result",
            name: "group-flight-result",
            component: load("FlightResultView"),
            meta: { title: "Group Dashboard Add Reservation", noCache: true },
            beforeEnter: groupauth,
        },
        {
            path: "booking/requests",
            name: "group-booking-requests",
            component: load("BookingRequestsView"),
            meta: { title: "Group Dashboard Add Reservation", noCache: true },
            beforeEnter: groupauth,
        }, 
        {
            path: "booking/details/:id",
            name: "group-booking-details",
            component: load("BookingRequestDetailsView"),
            meta: { title: "Group Dashboard Add Reservation", noCache: true },
            beforeEnter: groupauth,
        }, 
        {
            path: "employee",
            name: "group-employee",
            component: load("EmployeesView"),
            meta: { title: "Group Dashboard All Employee", noCache: true },
            beforeEnter: groupauth,
        },
    ]
};

export default GroupAuth;