import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./locales/i18n";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import { createHead } from "@vueuse/head";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "light-icons/dist/light-icon.css";
import 'vue-select/dist/vue-select.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@vuepic/vue-datepicker/dist/main.css';
import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import PusherPlugin from './plugins/pusher';
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
});

// for translate pages
let lang = localStorage.getItem("lang") ?? "ar";
document.documentElement.lang = lang;
localStorage.setItem("lang", lang);
/** End Notification */

const optionsSweet = {
    confirmButtonColor: 'rgb(209, 175, 119)',
};

// Vue3Toasity

const optionsVue3Toasity = {
    "position": "top-center",
    "rtl": true,
    "dangerouslyHTMLString": true
};


createApp(App)
    .use(PusherPlugin, { apiKey: 'ab9a5e8365c71b1df075', cluster: 'ap2' })
    .use(store)
    .use(i18n) 
    .use(ElementPlus)
    .use(Vue3Toasity, optionsVue3Toasity)
    .use(VueSweetalert2, optionsSweet)
    .use(createHead())
    .use(router)
    .use(vuetify)
    .mount("#app");

const app = createApp(App)
app.config.globalProperties.$appUrl = 'https://jsky.rs4it.com/api_v2/public' 
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
} 