import { createStore } from "vuex";
import notification from "./notification";
const store = createStore({
  state: {
  },

  getters: {},
  mutations: {

  },
  actions: {},
  modules: {
    notification,
    
  },
});
export default store;
