<template>
    <div class="box" dir="auto">
        <div class="content">
            <div class="formbox">
                <h5 class="text-center  color mb-5">{{ $t('Enter New Password') }}</h5>
                <form class=" w-100" @submit.prevent="ResetPassword">
                    <div class="position-relative">
                        <label for="" class="form-label">{{ $t('New Password') }}</label>
                        <input v-model="formbody.password" required autocomplete="new-password" type="password"
                            class="form-control">
                        <i @click="showPassword" class="light-icon-eye"></i>
                    </div>
                    <div class="mt-4 mb-4 position-relative">
                        <label for="" class="form-label">{{ $t('Confirm Password') }}</label>
                        <input v-model="formbody.confirm_password" required autocomplete="new-password" type="password"
                            class="form-control">
                        <i @click="showPassword" class="light-icon-eye"></i>
                    </div>
                    <div class="text-center">
                        <button type="submit"
                            :disabled="formbody.password == '' || formbody.confirm_password == '' || (formbody.password != formbody.confirm_password)"
                            class="btn py-2 text-white special_btn mt-4">
                            <span>{{ $t('Reset Password') }}</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="image">
            <img class="logoregister" src="@/assets/images/register/login.png" alt="RegisterLogo" />
            <!-- JSKY Logo -->
            <img src="@/assets/images/logo/logo1.png" class="logoimage" alt="logo1" />
            <div class="text-box">
                <h2 class="text-white mb-3">{{ $t("Business login") }}</h2>
                <h5 class="text-white">
                    {{ $t("Password Reset") }}
                </h5>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import { toast } from "vue3-toastify";
import authenticationServices from '@/services/business/auth/auth-service'
import { useRoute, useRouter } from 'vue-router'
export default {
    name: "ResetPasswordView",
    data() {
        return {
            loading: false,
            router: useRouter(),
            route: useRoute(),
            formbody: {
                password: "",
                confirm_password: "",
                lang: localStorage.getItem('lang'),
                random_url_token: ""
            }
        }
    },

    methods: {
        showPassword(event) {
            if (event.target.parentNode.children[1].getAttribute("type") == null || event.target.parentNode.children[1].getAttribute("type") == 'password') {
                event.target.parentNode.children[1].setAttribute('type', 'text')
            } else {
                event.target.parentNode.children[1].setAttribute('type', 'password')
            }
        },
        async ResetPassword() {
            $(".pre-loader").show();
            this.formbody.random_url_token = this.route.params.id
            await authenticationServices.reset_password(this.formbody).then(res => {
                $(".pre-loader").hide();
                if (res.status == 0) {
                    toast.error(res.message)
                } else {
                    toast.success(res.data.message)
                    setTimeout(() => {
                        this.router.push('/')
                    }, 1500);
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>

.innerbox {
    border-radius: 15px;
    padding: 1rem 8rem;
    width: 80% !important;
    box-shadow: #d1af7797 0px 1px 2px 0px, #d1af77a9 0px 1px 1px 1px;

}


.box {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;

    .image {
        width: 35%;
        height: 100%;
        position: relative;

        .logoregister {
            width: 100% !important;
            height: 100% !important;
        }

        .logoimage {
            width: 270px;
            position: absolute;
            top: 10%;
            left: 50%;
            transform: translateX(-50%);
        }

        .text-box {
            width: 100%;
            position: absolute;
            top: 24%;
            text-align: center;
            padding: 1rem;
            left: 0%;
        }

        .problem {
            width: 100%;
            position: absolute;
            bottom: 5%;
            text-align: left;
            padding: 1rem;
            left: 0%;
        }
    }

    .content {
        width: 65%;
        height: 100%;
        padding: 1rem;

        .formbox {
            border-radius: 5px;
            padding: 2rem 2rem;
            width: 55%;
            box-shadow: rgb(0 0 0 / 15%) 0px 1px 5px;   
            margin: 9rem auto;
        }
    }
}

@media screen and (max-width: 992px) {

    .text-ingo {
        display: block !important;
    }

    .box {
        flex-direction: column !important;
        height: auto !important;

        .image {
            width: 100% !important;
            height: auto !important;
        }

        .content {
            width: 100%;
            height: auto !important;
        }

        .formbox {
            margin: 1rem auto !important;
            width: 98% !important;
        }
    }
}

.light-icon-eye {
    position: absolute;
    top: 55%;
    left: 3%;
}
.form-control{
    height: 45px;
}
</style>