
export default {
    namespaced: true,
    state: {
        AllNotification: [],
        newnotificationCount: 0

    },
    getters: {
        commentsCount(state) {
            return state.newnotificationCount;
        },
        AllNotificaion(state) {
            return state.AllNotification;
        },
    },
    mutations: {
        SET_ALL_NOTIFICATION(state,payload){
            if(state.AllNotification.length == 0){
                state.AllNotification = payload
                payload.forEach(item => {
                    if(item.seen == 0) {
                        state.newnotificationCount++
                    }
                })
            }
        },
        makeAllNotificaionread(state){
            state.AllNotification.forEach(item => {
                item.seen = 1
            })
            state.newnotificationCount = 0
        }
        
    },
    actions: {},
};
