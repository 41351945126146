<template>
    <div class="mainForm new-booking">
        <!-- From To -->
        <div class="row">
            <div class="col-lg-5  col-12">
                <label for="exampleFormControlInput1" class="form-label mb-0">
                    {{ $t('From') }}
                    <img class="mx-2" src="@/assets/images/addreservation/from.png"
                        :style="$i18n.locale == 'en' ? '' : 'transform: rotateY(180deg)'" height="30" alt="">
                </label>
                <div class="el-select  d-block mb-2">
                    <div class="select-trigger el-tooltip__trigger el-tooltip__trigger">
                        <div class="el-input el-input--large el-input--suffix">
                            <div class="el-input__wrapper" role="button" tabindex="-1">
                                <input type="text" v-model="departure_name" :placeholder="$t('City or Airport')"
                                    class="search-input" @input="handle_airports_search_departure">
                                <span class="departure_code">
                                    {{ departure }}
                                </span>
                                <ul v-if="is_departure_searching" tabindex="0"
                                    class="ui-id-1 ui-menu ui-widget ui-widget-content ui-autocomplete ui-front">
                                    <li class="fl-route-list ui-autocomplete-category ui-state-disabled ui-menu-item"
                                        aria-disabled="true">
                                        <a class="fl-routes ui-menu-item-wrapper" id="ui-id-8" tabindex="-1">
                                            {{ $t('Nearest airport') }}
                                        </a>
                                    </li>
                                    <li v-for="(airport, index) in airports_data" class="fl-route-list ui-menu-item"
                                        :key="index" @click="selectDepartureAirport(airport)">
                                        <a class="fl-routes ui-menu-item-wrapper" tabindex="-1">
                                            <div class="inline-block airportMargin">
                                                <span class="fl-loc">
                                                    {{ this.$i18n.locale ==
                        'en' ? airport.en_name :
                        airport.ar_name
                                                    }}
                                                </span>
                                                <span class="fl-country">
                                                    {{
                        this.$i18n.locale ==
                            'en' ? airport.en_city :
                            airport.ar_city
                    }}
                                                    ,
                                                    {{ this.$i18n.locale ==
                        'en'
                        ? airport.en_country :
                        airport.ar_country
                                                    }}
                                                </span>
                                            </div>
                                            <div class="pull-right">
                                                <h4 class="fl-route-code">{{
                        airport.code }}
                                                </h4>
                                            </div>
                                        </a>
                                    </li>
                                    <li v-if="noResults" class="fl-route-list ui-menu-item no-results">
                                        {{ $t('No results!') }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-12 d-flex justify-content-center align-items-end">
                <img :style="$i18n.locale == 'en' ? '' : 'transform: rotate(180deg)'"
                    src="@/assets/images/addreservation/Icon.png" height="30" alt="">
            </div>
            <div class="col-lg-5  col-12">
                <label for="exampleFormControlInput2" class="form-label mb-0">
                    {{ $t('To') }}
                    <img class="mx-2" :style="$i18n.locale == 'en' ? '' : 'transform: rotateY(180deg)'"
                        src="@/assets/images/addreservation/to.png" height="30" alt="">
                </label>
                <div class="el-select  d-block mb-2">
                    <div class="select-trigger el-tooltip__trigger el-tooltip__trigger">
                        <div class="el-input el-input--large el-input--suffix">
                            <div class="el-input__wrapper" role="button" tabindex="-1">
                                <input type="text" v-model="arrival_name" :placeholder="$t('City or Airport')"
                                    class="search-input" @input="handle_airports_search_arrival">
                                <span class="departure_code">
                                    {{ arrival }}
                                </span>
                                <ul v-if="is_arrival_searching" tabindex="0"
                                    class="ui-id-1 ui-menu ui-widget ui-widget-content ui-autocomplete ui-front">
                                    <li class="fl-route-list ui-autocomplete-category ui-state-disabled ui-menu-item"
                                        aria-disabled="true">
                                        <a class="fl-routes ui-menu-item-wrapper" id="ui-id-8" tabindex="-1">
                                            {{ $t('Nearest airport') }}
                                        </a>
                                    </li>
                                    <li v-for="(airport, index) in airports_data" class="fl-route-list ui-menu-item"
                                        :key="index" @click="selectArrivalAirport(airport)">
                                        <a class="fl-routes ui-menu-item-wrapper" tabindex="-1">
                                            <div class="inline-block airportMargin">
                                                <span class="fl-loc">
                                                    {{ this.$i18n.locale ==
                        'en' ? airport.en_name :
                        airport.ar_name
                                                    }}
                                                </span>
                                                <span class="fl-country">
                                                    {{
                        this.$i18n.locale ==
                            'en' ? airport.en_city :
                            airport.ar_city
                    }}
                                                    ,
                                                    {{ this.$i18n.locale ==
                        'en'
                        ? airport.en_country :
                        airport.ar_country
                                                    }}
                                                </span>
                                            </div>
                                            <div class="pull-right">
                                                <h4 class="fl-route-code">{{
                        airport.code }}
                                                </h4>
                                            </div>
                                        </a>
                                    </li>
                                    <li v-if="noResults" class="fl-route-list ui-menu-item no-results">
                                        {{ $t('No results!') }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- From To -->

        <!-- Time -->
        <div class="row mt-3">
            <div class="col-lg-12">
                <label for="exampleFormControlInput1" class="form-label">
                    {{ $t('Departure Date') }}
                </label>

                <input class="form-control" type="date" v-model="date" :min="mindate">
            </div>
        </div>
        <!-- Time -->

        <!-- Degree -->
        <div class="row mt-3">
            <div class="col-lg-12 ">
                <label for="exampleFormControlInputDegree" class="form-label">
                    {{ $t('Degree') }}
                </label>
                <v-locale-provider v-bind:rtl="this.$i18n.locale ==
                        'en' ? false : true">
                    <v-select label="" v-model="cabin" variant="underlined" :items="cabins" item-title="title"
                        item-value="value" hide-details="auto"></v-select>
                </v-locale-provider>
                <img class="degreeimage" :class="$i18n.locale == 'en' ? 'degreeimage_en' : 'degreeimage_ar'"
                    src="@/assets/images/addreservation/degree.png" alt="">
            </div>
        </div>
        <!-- Degree -->

        <!-- propuse -->
        <div class="row mt-3 mb-5">
            <div class="col-lg-12 ">
                <label for="exampleFormControlInputpropuse" class="form-label">
                    {{ $t('travel-purposes') }}
                </label>
                <v-locale-provider v-bind:rtl="this.$i18n.locale ==
                        'en' ? false : true">
                    <v-autocomplete label="" v-model="purpose" :items="travel_proupose"
                        :loading="loader_travel_prouposes" @update:search="search" item-title="title" item-value="value"
                        hide-details="auto">
                    </v-autocomplete>
                </v-locale-provider>
            </div>
        </div>
        <!-- propuse -->

        <!-- For Search -->
        <div class="search mt-3 px-3 py-1">
            <div @click="show = !show" class="titlesearch d-flex justify-content-between align-items-center pointer">
                <span>{{ $t('Search') }}</span>
                <i class="light-icon-search fs-5"></i>
            </div>
            <div v-if="show" class="mt-4">
                <div class="mb-3">
                    <div class="row">

                        <div class="col-lg-4 col-12">
                            <input type="text" v-model="search_data.first_name" class="inputforsearch"
                                :placeholder="$t('First Name')">
                        </div>
                        <div class="col-lg-4 col-12">
                            <input type="text" v-model="search_data.last_name" class="inputforsearch"
                                :placeholder="$t('Last Name')">
                        </div>
                        <div class="col-lg-4 col-12">
                            <input type="email" v-model="search_data.email" class="inputforsearch"
                                :placeholder="$t('email')">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end mb-4 mt-4">
                    <button class="btn special_btn text-white" @click="search_employee()"
                        :disabled="search_data.first_name == '' && search_data.last_name == '' && search_data.email == ''">{{
                        $t('apply') }}</button>
                    <button class="btn btn-danger mx-3" @click="DeleteAllDataForSearch">{{ $t('delete') }}</button>
                    <button class="btn btn-reset" @click="reset_search_employee">{{ $t('reset') }}</button>
                </div>
            </div>
        </div>
        <!-- For Search -->

        <!-- Table For Result -->
        <div class="mt-3">
            <div class="table-responsive bodyresult">
                <vue3-datatable ref="datatable" :rows="rows" :columns="cols" :loading="loading" :totalRows="total_rows"
                    :isServerMode="true" :pageSize="params.pagesize" :showNumbers="false" :showFirstPage="false"
                    :showLastPage="false" :showPageSize="false" :hasCheckbox="true"
                    :paginationInfo="'{1} ' +$t('of')+ ' {2}'" @change="changeServer">
                    <template #first_name="data">
                        {{ data.value.first_name }} {{ data.value.last_name }}
                    </template>
                    <template #job_title="data">
                        {{ data.value.job_title??'-' }}
                    </template>
                </vue3-datatable>
            </div>
        </div>
        <!-- Table For Result -->

        <!-- Next -->
        <div class="d-flex justify-content-center mt-5">
            <button class="btn special_btn text-white" @click="get_result">
                <span>{{ $t('next') }}</span>
            </button>
        </div>
        <!-- Next -->
    </div>
</template>

<script>
import { toast } from "vue3-toastify";
import { debounce } from 'lodash';
import jsonData from '@/assets/files/airports-v2.json';
import Vue3Datatable from '@bhplugin/vue3-datatable';
import '@bhplugin/vue3-datatable/dist/style.css';
export default {
    name: "OnlyGoComponent",
    props: ["loading", "rows", "total_rows", "params", "travel_proupose", 'travel_prouposes_params', 'loader_travel_prouposes'],
    components: {
        Vue3Datatable,
    },
    data() {
        return {
            mindate: new Date().toISOString().slice(0, 10),
            departure: '',
            arrival: '',
            departure_name: '',
            arrival_name: '',
            is_departure_searching: false,
            is_arrival_searching: false,
            cabin: { title: this.$t('select2'), value: '' },
            cabins: [
                { title: this.$t('select2'), value: '' },
                { title: this.$t('First class'), value: 'FIRST' },
                { title: this.$t('Business'), value: 'BUSINESS' },
                { title: this.$t('tourism'), value: 'ECONOMY' },
            ],
            purpose: { title: this.$t('select2'), value: '' },
            date: "",
            show: false,
            noResults: false,
            airports_data: [],
            search_data: {
                first_name: '',
                last_name: '',
                email: '',
                job_title: '',
                id: '',
            },
            cols: [
                { field: 'first_name', title: this.$t('Employee Name') },
                { field: 'job_title', title: this.$t('Job title') },
            ],
            datatable: null,
        }
    },
    methods: {
        search: debounce(function (e) {
            this.search_travel_prouposes(e);
        }, 500),
        async search_travel_prouposes(e) {
            this.travel_prouposes_params.search = e;
            await this.$emit('get_travel_prouposes', this.travel_prouposes_params);
        },
        changeServer(data) {
            this.params.page = data.current_page;
            this.params.pagesize = data.pagesize;
            this.$emit('get_all_employees', this.params)
        },
        search_employee() {
            this.$emit('search_employee', this.search_data)
        },
        DeleteAllDataForSearch() {
            this.search_data.first_name = ''
            this.search_data.last_name = ''
            this.search_data.email = ''
        },
        reset_search_employee() {
            this.DeleteAllDataForSearch()
            this.$emit('reset_search_employee', this.search_data)

        },
        handle_airports_search_arrival: debounce(function () {
            this.searchAirPortsArrival(this.arrival_name);
        }, 500),
        searchAirPortsArrival(val) {
            this.noResults = false;
            if (val == '') {
                this.is_arrival_searching = false;
                return;
            } else {
                this.is_arrival_searching = true;
            }
            this.airports_data = jsonData.filter(item => {
                if (item.ar_name == null ||
                    item.code == null ||
                    item.ar_city == null ||
                    item.ar_state == null ||
                    item.ar_country == null ||
                    item.en_name == null ||
                    item.en_city == null ||
                    item.en_state == null ||
                    item.en_country == null) {
                    return false;
                } else {
                    return item.ar_name.toLowerCase().includes(val.toLowerCase()) ||
                        item.code.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_city.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_state.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_country.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_name.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_city.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_state.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_country.toLowerCase().includes(val.toLowerCase());
                }

            });
            if (this.airports_data.length == 0) {
                this.noResults = true;
            }
        },
        selectArrivalAirport(data) {
            this.is_arrival_searching = false;
            this.arrival = data['code'];
            this.arrival_name = data[this.$i18n.locale + '_name'];
        },
        handle_airports_search_departure: debounce(function () {
            this.searchAirPortsDeparture(this.departure_name);
        }, 500),
        searchAirPortsDeparture(val) {
            this.noResults = false;
            if (val == '') {
                this.is_departure_searching = false;
                return;
            } else {
                this.is_departure_searching = true;
            }
            this.airports_data = jsonData.filter(item => {
                if (item.ar_name == null ||
                    item.code == null ||
                    item.ar_city == null ||
                    item.ar_state == null ||
                    item.ar_country == null ||
                    item.en_name == null ||
                    item.en_city == null ||
                    item.en_state == null ||
                    item.en_country == null) {
                    return false;
                } else {
                    return item.ar_name.toLowerCase().includes(val.toLowerCase()) ||
                        item.code.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_city.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_state.toLowerCase().includes(val.toLowerCase()) ||
                        item.ar_country.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_name.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_city.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_state.toLowerCase().includes(val.toLowerCase()) ||
                        item.en_country.toLowerCase().includes(val.toLowerCase());
                }

            });
            if (this.airports_data.length == 0) {
                this.noResults = true;
            }
        },
        selectDepartureAirport(data) {
            this.is_departure_searching = false;
            this.departure = data['code'];
            this.departure_name = data[this.$i18n.locale + '_name'];
        },
        isObject(item) {
            return (typeof item === "object" && !Array.isArray(item) && item !== null);
        },
        async get_result() {
            var currency = 'SAR',
                date = this.date,
                ret_date = '',
                adults = this.$refs.datatable.getSelectedRows().length,
                children = 0,
                infants = 0,
                cabin = this.cabin,
                nonStop = false,
                max = 250,
                maxPrice = "",
                from = this.departure,
                to = this.arrival;
            if (from == '' || to == '') {
                toast.error(this.$t('Invalid Location'), {
                    autoClose: 3500,
                    position: toast.POSITION.TOP_CENTER,
                    closeButton: false
                });
            } else if (this.date == '') {
                toast.error(this.$t('Invalid Date'), {
                    autoClose: 3500,
                    position: toast.POSITION.TOP_CENTER,
                    closeButton: false
                });
            } else if (this.isObject(this.cabin) || this.cabin == '') {
                toast.error(this.$t('Class of booking required'), {
                    autoClose: 3500,
                    position: toast.POSITION.TOP_CENTER,
                    closeButton: false
                });
            } else if (this.isObject(this.purpose) || this.purpose == '') {
                toast.error(this.$t('Purpose of travel required'), {
                    autoClose: 3500,
                    position: toast.POSITION.TOP_CENTER,
                    closeButton: false
                });
            } else if (adults == 0) {
                toast.error(this.$t('Please select the passenger'), {
                    autoClose: 3500,
                    position: toast.POSITION.TOP_CENTER,
                    closeButton: false
                });
            } else {
                var passengers = [], passengersDetails = [], all_completed = true;
                this.$refs.datatable.getSelectedRows().forEach(element => {
                    passengers.push(element.passengers[0].id);
                });
                this.$refs.datatable.getSelectedRows().forEach(element => {
                    passengersDetails.push(element.passengers[0]);
                    if (element.passengers[0].IDNumber == null || element.passengers[0].id_issuing_country == null || element.passengers[0].id_expiry_date == null) {
                        all_completed = false;
                    }
                });
                if (!all_completed) {
                    toast.error(this.$t('Employee information must be completed in order to book.'), {
                        autoClose: 3500,
                        position: toast.POSITION.TOP_CENTER,
                        closeButton: false
                    });
                    return;
                }
                localStorage.setItem('purpose', this.purpose);
                localStorage.setItem('passengers', JSON.stringify(passengers));
                localStorage.setItem('passengersDetails', JSON.stringify(passengersDetails));
                this.$router.push(`/flight-result/result?currency=${currency}&from=${from}&to=${to}&date=${date}&ret_date=${ret_date}&adults=${adults}&children=${children}&infants=${infants}&cabin=${cabin}&nonStop=${nonStop}&maxPrice=${maxPrice}&max=${max}&tripType=oneway`);
            }
        },
    },
}
</script>

<style lang="scss" scoped>

.description {
    .price_description {
        background-color: white;
        border: 1px solid white;
        border-radius: 12px;
        padding: 5px 1rem;

    }
    .title_price {
        color: #888888;
    }

    .desc {
        height: 300px;
        overflow-y: auto;
        
    }

    .passenger {
        background-color: white;
            border: 1px solid white;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            padding: 15px 1rem;
    }

    .color {
        color: #D1AF77;
    }

    .color-1::after {
        content: ("");
        height: 2px !important;
        width: 90%;
        position: absolute;
        bottom: -3px;
        background-color: #D1AF77;
        left: 50%;
        transform: translateX(-50%);
    }

    .te {
        font-family: Cairo;
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0.5px;

    }
    .te1 {
        font-family: Cairo;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;


    }
}
.boxloadinginaddnewreservation {
    svg {
        width: 100% !important;
    }
}
.mainForm {
        // background-color: red;
        padding: 2rem 1rem;

        .search {
            background-color: #1C2126;
            box-shadow: 0px 11px 23px 0px #00000005;
            border: 1px solid #373b3e;
            color: #B9B9B9;
            border-radius: 5px;

            .inputforsearch {
                background-color: transparent;
                width: 100%;
                border: none;
                border-bottom: 1px solid #ffffff;
                color: #ffffff;

                &:focus {
                    outline: none !important;
                    border: none;
                    border-bottom: 1px solid #ffffff;
                }

                &::placeholder {
                    color: #B9B9B9;
                }
            }

            .btn-reset {
                background-color: #323940 !important;
                border: 1px solid #D1AF77;
                color: white;
            }


        }
    }
.form-control {
    background-color: transparent;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #D1AF77;
    color: white;
    

    &::placeholder {
        color: #ffffff;
    }
}

.form-control:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent;
    border: none;
    color: white !important;
    border-bottom: 1px solid #D1AF77;
}

.degreeimage {
    display: none;
}

.degreeimage_en {
    right: 5%;
}
.degreeimage_ar {
    left: 5%;
}

select {
    background-color: transparent !important;
    border: none;
    border-bottom: 1px solid #D1Af77;
    color: white;

    option {
        background-color: transparent !important;
        padding: 0.1rem 0.5rem !important;
        margin: 5px 0 !important;
        color: #272E35 !important;
    }
}
.el-input--large .el-input__wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
    z-index: 1;
}
.ui-id-1,
.ui-id-2 {
        max-height: calc(100vh - 460px);
        overflow-y: auto;
        border: 1px solid #f5eefd;
        background: #fff;
        color: #333;
        list-style: none;
        padding: 0;
        display: block;
        outline: 0;
        border-radius: 2px;
        line-height: 1.5;
    &::-webkit-scrollbar {
        width: 6px !important;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #e3e3e3 !important;
        border-radius: 25px !important;
    }

    &::-webkit-scrollbar-thumb {
        background: #d1af77 !important; 
        border-radius: 25px !important;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #d1af77 !important;
    }
    a {
        transition: all .3s ease-in-out .1s;
        outline: 0!important;
        color: #d1af77;
    }
    .no-results{
        text-align: center;
    }
}

.ui-autocomplete {
    position: absolute;
    top: 42px;
    left: 0;
    right: 0;
    text-align: initial;
    cursor: default;
    z-index: 1060;
}

.ui-menu .ui-menu-item.fl-route-list.ui-autocomplete-category {
    pointer-events: none;
    opacity: 1 !important;
}

.fl-route-list {
    border: 1px solid transparent;
    border-bottom-color: #f0eeee;
    cursor: pointer;
}

.fl-route-list.ui-autocomplete-category .fl-routes,
.fl-route-list.ui-autocomplete-category .fl-routes:hover {
    pointer-events: none;
    color: #d1af77;
    ;
    cursor: pointer;
}

.fl-routes {
    display: block;
    outline: 0 !important;
    text-decoration: none !important;
    padding: 10px !important;
    border-color: transparent !important;
    border-bottom-color: #f0eeee;
    position: relative;
    white-space: normal;
}

.inline-block {
    display: inline-block;
}

.fl-loc {
    font-weight: 600;
}

.fl-routes .fl-country {
    display: block;
    font-size: 12px;
    margin-top: 3px;
    color: #b3b3b3;
}

 .pull-right {
    float: left !important;
}

.fl-routes .fl-route-code {
    font-size: 18px;
    margin: 0;
    margin-top: 5px;
    font-weight: 700;
}

.range_plan {
    cursor: pointer;
}

.dp__input_readonly {
    padding-right: 40px !important;
}

input.chk-cabin {
    display: none;
}

.label-select-btn:has(input:checked) {
    background: #d1af77;
    color: var(--white-color);
}

.label-select-btn label {
    width: 100%;
    text-align: initial;
}

.passengers-type {
    i {
        vertical-align: middle;
    }
}
.search-input{
    width: 100%;height: 38px;
    line-height: 0;
    max-height: 38px;
    background-color: #272e35;
    border: none !important;
    outline: none;
    color: #d1af77;
}
input,select {
    color-scheme: dark;
  }

</style>