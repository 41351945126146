import Api from "@/api/api";
import authHeader from "@/services/business/auth/auth-header";

const END_POINT = "api/business/travel-purposes/";

class travelPurposes {

    Get_All_TravelProupose(params) {
        return Api.get(END_POINT + "all", { headers: authHeader(), params })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }

    Add_New_TravelProupose(data) {
        return Api.post(END_POINT + "store", data, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }

    ChangeStatus(id, status, lang) {
        return Api.get(END_POINT + `change-status/${id}/${status}?lang=${lang}`, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }

    GetDetail(id) {
        return Api.get(END_POINT + `edit/${id}`, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }

    Delete_TravelProupose(id, lang) {
        return Api.get(END_POINT + `delete/${id}?lang=${lang}`, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }


    Edit_TravelProupose(id, data) {
        return Api.post(END_POINT + `update/${id}`, data, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }


}

export default new travelPurposes();