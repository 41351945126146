function load(component) {
    return () => import(`@/views/business/company/${component}.vue`);
}
function loadGroup(component) {
    return () => import(`@/views/business/company/groups/${component}.vue`);
}

const auth = (to, from, next) => {
    if (localStorage.getItem("business_token")) {
        return next();
    } else {
        return next("/login");
    }
};

const CompanyRoutes = {
    path: "/company",
    alwaysShow: true,
    beforeEnter: auth,
    children: [
        {
            path: "employees",
            name: "employees",
            component: load("EmployeesView"),
            meta: { title: "employee", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "employees/add",
            name: "add-employee",
            component: load("AddEmployeesByDataView"),
            meta: { title: "Add employee", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "employees/addbyinvitaion",
            name: "employees-invitaion",
            component: load("AddEmployeesByinvitaionView"),
            meta: { title: "Add employee", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "employees/edit/:id",
            name: "edit-employee",
            component: load("EditEmploeyView"),
            meta: { title: "Edit employee", noCache: true },
            beforeEnter: auth,
        },

        {
            path: "/invited/list",
            name: "invitaions",
            component: load("invitaionsView"),
            meta: { title: "invitaions", noCache: true },
            beforeEnter: auth,
        },
        
        // Groups
        {
            path: "groups",
            name: "groups",
            component: loadGroup("GroupsView"),
            meta: { title: "Groups", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "groups/details/:id",
            name: "group-details",
            component: loadGroup("GroupdetailsView"),
            meta: { title: "group details", noCache: true },
            beforeEnter: auth,
        },
        // Groups
        {
            path: "subsidiaries",
            name: "subsidiaries",
            component: load("SubsidiariesView"),
            meta: { title: "Subsidiaries", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "units",
            name: "units",
            component: load("UnitsView"),
            meta: { title: "units", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "divisions",
            name: "divisions",
            component: load("DivisionsView"),
            meta: { title: "Sections", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "departments",
            name: "departments",
            component: load("DepartmentView"),
            meta: { title: "department", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "branches",
            name: "branches",
            component: load("BranchView"),
            meta: { title: "branch", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "cost-centers",
            name: "cost-centers",
            component: load("CoastCenterView"),
            meta: { title: "coastcenter", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "profile",
            name: "company-account",
            component: load("CompanyProfileView"),
            meta: { title: "Profile", noCache: true },
            beforeEnter: auth,
        }
    ]
};

export default CompanyRoutes;