import Api from "@/api/api";
import authHeader from "@/services/business/auth/auth-header";
const END_POINT = "api/business/";
class AuthenticationService {
    Login(data) {
        return Api.post(END_POINT + "auth/login", data, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    RegisterNewEmail(data) {
        return Api.post(END_POINT + "auth/signup", data, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    CheckCode(data) {
        return Api.post(END_POINT + "auth/CheckCode", data, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    forgot_password(data) {
        return Api.post(END_POINT + "auth/forgot_password", data, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    reset_password(data) {
        return Api.post(END_POINT + "auth/reset_password", data, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    get_user_data() {
        return Api.get(END_POINT + "get_user_data", { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    get_company() {
        return Api.get(END_POINT + "get_company", { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    logout() {
        return Api.get("/api/logout", { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
}
export default new AuthenticationService();