import Api from "@/api/api";
import authHeader from "@/services/business/auth/auth-header";
const END_POINT = "api/business/support/";
class ChatService {
    get_all_messages() {
        return Api.get(END_POINT + "get_chat_messages", { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
}
export default new ChatService();