function load(component) {
    return () =>
        import (`@/views/business/bookings/${component}.vue`);
}
function load2(component) {
  return () => import(`@/views/business/flights/${component}.vue`);
}
const auth = (to, from, next) => {
    if (localStorage.getItem("business_token")) {
        return next();
    } else {
        return next("/login");
    }
};
const BookingRoutes = {
    path: "/booking",
    alwaysShow: true,
    beforeEnter: auth,
    children: [{
            path: "requests",
            name: "booking-requests",
            component: load("BookingView"),
            meta: { title: "Booking", noCache: true },
            beforeEnter: auth,
        },
        {
            path: "flights",
            name: "flights",
            component: load2("FlightsView"),
            meta: { title: "Trips", noCache: true },
            // beforeEnter: auth,
        },
        {
            path: "details/:id",
            name: "booking-details",
            component: load2("BookingRequestView"),
            meta: { title: "Trips", noCache: true },
            // beforeEnter: auth,
        },
    ]
};

export default BookingRoutes;