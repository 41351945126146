<template>
    <form @submit.prevent="register" class="register">
        <div>
            <div class="mb-2">
                <lv-input id="CompanyName" type="text" v-model="company_name" :label="$t('Company Name')"
                    :placeholder="$t('Company Name')" placeholder-color="#84818A" />
            </div>
            <!-- Name -->
            <div class=" row d-flex justify-content-between">
                <div class="col-lg-4 col-12 mb-3">
                    <lv-dropdown :class="$i18n.locale == 'en' ? '' : 'd-none'" v-model="title" :options="titles"
                        optionValue="id" :optionLabel="$i18n.locale == 'en' ? 'name' : 'arname'" :label="$t('Title')"
                        :placeholder="$t('Select Title')" />
                </div>
                <div class="col-lg-4 col-12 mb-3">
                    <label class="mb-2" style="color: #84818A;">{{ $t('Manager first name') }}</label>
                    <input type="text" v-model="first_name" class="form-control" :placeholder="$t('First Name')">
                </div>
                <div class="col-lg-4 col-12 mb-3">
                    <label class="mb-2" style="color: #84818A;">{{ $t('Manager last name') }}</label>
                    <input type="text" v-model="last_name" class="form-control" :placeholder="$t('Last Name')">
                </div>
            </div>
            <!-- Phone -->
            <div class=" row d-flex justify-content-between">
                <div class="col-lg-2 col-12 mb-3 ">
                    <label for="exampleDataListCountryCode" style="color: #84818A;" class="form-label">{{ $t('phone')
                        }}</label>
                    <div class="dropdown ">
                        <input type="text" @keyup="ChangeInCountryList" class="form-control"
                            id="dropdownMenuButtonCountry" data-bs-toggle="dropdown" aria-expanded="false">
                        <div class="showCountryItem" @click="ChangeInCountryList" v-if="CountryItem != null">
                            <span>
                                <i class="mx-2" :class="`flag flag-${CountryItem.code}`"></i>
                                <span dir="ltr">{{ CountryItem.dial_code }}</span>
                            </span>
                        </div>
                        <ul class="dropdown-menu dropdown-menu-country" aria-labelledby="dropdownMenuButtonCountry">
                            <li @click="SetCountry(item)"
                                class="lifromcountry pointer d-flex justify-content-between align-items-center"
                                v-for="item in countriesInSelect" :key="item">
                                <span>
                                    {{ $i18n.locale == 'en' ? item.en_name : item.name }}
                                </span>
                                <span>
                                    <span dir="ltr" class="dial_code">{{ item.dial_code }}</span>
                                    <i :class="`flag flag-${item.code}`"></i>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-10 col-12 mb-3">
                    <label class="mb-2 v-hidden">{{ $t('phone') }}</label>
                    <input :readonly="CountryItem == null" type="number" required class="form-control" v-model="phone">

                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label class="mb-2" style="color: #84818A;">{{ $t('Email for work') }}</label>
                    <input type="email" class="form-control" required v-model="email">
                </div>
                <div class="col-md-6">
                    <lv-input id="CompanyName" type="password" v-model="password"
                        :label="$t('Account manager password')" :placeholder="$t('Account manager password')"
                        icon-right="light-icon-eye" autocomplete="new-password" placeholder-color="#84818A" />
                </div>
            </div>
            <div class="overflow-hidden mt-5 mb-5">
                <small>
                    عند إنشاء حساب جديد فإنك توافق على
                    <a href="#" class="color-blue">
                        الشروط و الأحكام
                    </a>
                    و
                    <a href="#" class="color-blue">
                        سياسة الخصوصية ل جي سكاي
                    </a>
                </small>
            </div>
            <div class="mt-5 mb-5 d-flex justify-content-center align-items-center">
                <button type="submit" :disabled="diasable" class="w-50 btn text-white py-2"
                    style="background-color: #D1AF77">
                    <p class=" mb-0 ">{{ $t('Register an account') }}</p>
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import $ from 'jquery';
import { toast } from "vue3-toastify";
import authenticationService from "@/services/business/auth/auth-service"
import LvInput from 'lightvue/input';
import LvDropdown from 'lightvue/dropdown';
import LvCheckbox from 'lightvue/checkbox';
import ripple from 'lightvue/ripple';
import LvLoader from 'lightvue/loaders';
import country_code from "@/assets/files/country-code.json"
export default {
    name: 'RegisterComponent',
    components: {
        LvInput,
        LvDropdown,
        LvCheckbox,
        LvLoader
    },
    directives: {
        ripple: ripple,
    },
    mounted() {
        document.getElementsByClassName('light-icon-eye')[0].addEventListener("click", this.showPassword)
    },
    data() {
        return {
            diasable: true,
            checked: true,
            allcountries: country_code,
            countries: [],
            countriesInSelect: [],
            titles: [
                {
                    id: "mr",
                    name: 'Mr',
                    arname: 'السيد'
                },
                {
                    id: "ms",
                    name: 'Ms',
                    arname: 'السيدة'
                },
                {
                    id: "miss",
                    name: 'Miss',
                    arname: 'الآنسة'
                }
            ],
            title: "mr",
            company_name: "",
            first_name: "",
            last_name: "",
            phone: "",
            country_code: "",
            email: "",
            password: "",
            lang: localStorage.getItem('lang'),
            CountryItem: {
                "name": "المملكة العربية السعودية",
                "en_name": "Saudi Arabia",
                "dial_code": "+966",
                "code": "sa"
            }
        }
    },
    methods: {
        ChangeInCountryList(e) {
            this.CountryItem = null;
            if (e.type != 'click' && e.target.value != '') {
                let array = this.countries
                if (this.lang == 'en') {
                    this.countriesInSelect = array.filter(country => country.en_name.includes(e.target.value) || country.dial_code.includes(e.target.value))
                } else {
                    this.countriesInSelect = array.filter(country => country.name.includes(e.target.value) || country.dial_code.includes(e.target.value))
                }
            } else {
                this.countriesInSelect = this.countries
            }
        },
        SetCountry(item) {
            this.CountryItem = item
            document.getElementById('dropdownMenuButtonCountry').value = ''
        },
        showPassword(event) {
            if (event.target.parentNode.parentNode.parentNode.children[0].getAttribute("type") == null || event.target.parentNode.parentNode.parentNode.children[0].getAttribute("type") == 'password') {
                event.target.parentNode.parentNode.parentNode.children[0].setAttribute('type', 'text')
            } else {
                event.target.parentNode.parentNode.parentNode.children[0].setAttribute('type', 'password')
            }

        },
        async register() {
            $(".pre-loader").show();
            let data = {
                title: this.title,
                company_name: this.company_name,
                first_name: this.first_name,
                last_name: this.last_name,
                phone: this.phone,
                country_code: this.CountryItem.dial_code,
                email: this.email,
                password: this.password,
                lang: this.lang
            }
            await authenticationService.RegisterNewEmail(data).then(res => {
                $(".pre-loader").hide();
                if (res.status == 0) {
                    for (const field in res.data) {
                        if (Object.prototype.hasOwnProperty.call(res.data, field)) {
                            const errors = res.data[field];
                            errors.forEach(error => {
                                toast.error(error);
                            });
                        }
                    }
                } else {
                    toast.success(res.data.message);
                    setTimeout(() => {
                        this.$router.push({ name: "login", query: { email: this.email } });
                    }, 500);
                }
            })
        },
        CheckValues() {
            if (this.first_name == '' ||
                this.last_name == '' ||
                this.title == '' ||
                this.CountryItem == null ||
                this.phone == '' ||
                this.email == '' ||
                this.password == '' ||
                this.company_name == ''
            ) {
                this.diasable = true
            } else {
                this.diasable = false
            }
        }
    },
    watch: {
        title() { this.CheckValues() },
        first_name() {
            this.CheckValues();
            this.first_name = this.first_name.replace(/[^a-zA-Z\s]/g, '');
        },
        last_name() {
            this.CheckValues();
            this.last_name = this.last_name.replace(/[^a-zA-Z\s]/g, '');
        },
        phone() { this.CheckValues() },
        company_name() { this.CheckValues() },
        email() { this.CheckValues() },
        password() { this.CheckValues() },
        CountryItem() { this.CheckValues() }
    },
    created() {
        const arrayOfObj = Object.entries(country_code).map((e) => (e[1]));
        this.countries = arrayOfObj
        this.countriesInSelect = arrayOfObj
    }
}
</script>

<style lang="scss">
.register{
    .lv-dropdown__panel .lv-dropdown__items .lv-dropdown__item.--selected {
        color: #ffffff;
        background: #d1af77;
    }
    label {
        display: inline-block;
        font-size: 14px;
    }
    .lv-input__field,.form-control{
        height: 40px;
    }
}
</style>
<style lang="scss" scoped>
.form-control:focus{
    border-color: #dee2e6;
}
.lv-dropdown {
    width: 100%;
}
@media screen and (max-width: 992px) { 
    .w-50 {
        width: 100% !important;
    }
}

.dropdown-menu-country{
    max-height: 300px;
    border-radius: 0;
    overflow-y: auto;
    width: 100% !important;
    min-width: 250px;
    .lifromcountry {
        margin: 5px 0;
        padding: 5px 10px;
        font-size: 14px;
        &:hover {
            background-color: rgba(128, 128, 128, 0.477);
        }
        .dial_code{
            margin: 0px 8px;
        }
    }
}

.showCountryItem {
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .caret{
        display: inline-block;
        width: 0;
        height: 0;
        vertical-align: middle;
        border-top: 4px dashed;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        margin: 0px 4px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
</style>