<template>
  <NavbarComponent />
  <SidebarComponentVue />
  <div dir="auto">
    <div class="body_content" :class="$i18n.locale == 'en' ? '' : 'body_content_ar'">
      <FlightResultArea />
    </div>
  </div>
</template>
<script>
import SidebarComponentVue from '@/components/business/layout/SidebarComponent.vue';
import NavbarComponent from '@/components/business/layout/NavbarComponent.vue';
import FlightResultArea from '@/components/business/new-booking/FlightResultArea';
export default {
  name: "NewBookingView",
  components: {
    SidebarComponentVue,
    NavbarComponent,
    FlightResultArea
  },
};
</script>