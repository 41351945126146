<template>
    <div>
      <Doughnut 
          id="my-chart-id3"
            :data="data" :options="options"
        />
    </div>
</template>

<script>

import { 
  Doughnut
} from 'vue-chartjs'


import { Chart as ChartJS, Title, Tooltip,
  PointElement,
  LineElement,
  Legend, BarElement, CategoryScale,ArcElement, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip,
  PointElement,
  LineElement,
  Legend, BarElement, CategoryScale,ArcElement, LinearScale)




export default {
    name: "DoughnutComponent",
    components: {
      Doughnut
    },
    data() {
        return {
          data: {
            labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
            datasets: [
              {
                backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
                data: [40, 20, 80, 10]
              }
            ]
          },
          options: {
        responsive: true,
        maintainAspectRatio: false
      },
        }
    },
}
</script>

<style lang="scss" scoped></style>