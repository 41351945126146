import Api from "@/api/api";
import authHeader from "@/services/business/auth/auth-header";
const END_POINT = "api/business/employees/";
class EmployeeService {
    Gel_All_Invitaions(params) {
        return Api.get(END_POINT + "invitations/all", { headers: authHeader(), params })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    Invitaion_Stor(token, data) {
        return Api.post(END_POINT + `invitations/store/${token}`, data, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    Search_For_Employee(keyword) {
        return Api.get(END_POINT + "search_employees/" + keyword, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    Get_All_Employee(params) {
        return Api.get(END_POINT + "all", { headers: authHeader(), params })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    Search_In_All_Employee(data) {
        return Api.get(END_POINT + "all", { headers: authHeader(), params: { searchData: data } })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    Add_New_Employee(data) {
        return Api.post(END_POINT + "store", data, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    Delete_Employee(id, lang) {
        return Api.get(END_POINT + `delete/${id}?lang=${lang}`, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    Get_Employee_Detail(id) {
        return Api.get(END_POINT + `edit/${id}`, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    Edit_Employee(id, data) {
        return Api.post(END_POINT + `update/${id}`, data, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    invitation_Employee_By_Emails(data, lang) {
        return Api.post(END_POINT + `invitation`, data, { headers: authHeader(), params: { emails: data, lang: lang } })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    invitation_upload_file(data) {
        return Api.post(END_POINT + `invitation_upload_file`, data, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    import_store(data) {
        return Api.post(END_POINT + `import_store`, data, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
    get_employees_template() {
        return Api.get(END_POINT + `get_employees_template`, { headers: authHeader() })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response.data;
            });
    }
}
export default new EmployeeService();