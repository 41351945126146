<template>
    <div>
      <Pie 
          id="my-chart-id3"
            :data="data" :options="options"
        />
    </div>
</template>

<script>

import { 
  Pie
} from 'vue-chartjs'


import { Chart as ChartJS, Title, Tooltip,
  PointElement,
  LineElement,
  Legend, BarElement, CategoryScale,ArcElement, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip,
  PointElement,
  LineElement,
  Legend, BarElement, CategoryScale,ArcElement, LinearScale)




export default {
    name: "DoughnutComponent",
    components: {
        Pie
    },
    data() {
        return {
          data: {
            labels: ['Sat', 'Sun', 'Mon', 'Tus', 'Wed', 'Thursday', 'Fri'],
            datasets: [
              {
                label: this.$t('Number of reservations'),

                backgroundColor: ['#D1AF77', '#FFD964', '#FF4F4F', '#1BD344', '#3C65F5', '#FFF742', '#45fd56'],
                data: [40, 20, 80, 10, 30 ,40, 30]
              }
            ]
          },
          options: {
        responsive: true,
        maintainAspectRatio: false
      },
        }
    },
}
</script>

<style lang="scss" scoped></style>